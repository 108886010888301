import React, { useRef, useState, useEffect } from 'react';

import { animated, useSpring } from '@react-spring/web';
import moment from 'moment-timezone';

import { AltBadge } from 'views/Main.js';
import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import { CalloutIndex } from 'structure/Layer.js';
import PageControl from 'views/PageControl.js';
import PrintContent from 'views/PrintContent.js';
import ProgressBar from 'views/ProgressBar.js';
import TextField from 'views/TextField.js';
import Utils from 'files/Utils.js';

const Panel = ({ children, className, column, headerStyle, index, name, options = {}, style, utils }) => {

    const { buttons, callout, download, loading, maxWidth, onRefreshContent, onSizeChange, paging, print, removeOverflow, removePadding, rightContent, search, subTitle, total } = options || {};

    const contentContainer = useRef(null);

    const [animations, setAnimations] = useSpring(() => ({
        top: -50,
        opacity: 0,
        config: { mass: 1, tension: 180, friction: 12 }
    }));
    const [refreshButtonAnimations, refreshButtonAnimationsApi] = useSpring(() => ({
        config: { mass: 1, tension: 200, friction: 10 }
    }))
    const [showCallout, setShowCallout] = useState(false);
    const [sortContainer, setSortContainer] = useSpring(() => ({
        maxHeight: 0,
        config: { mass: 1, tension: 180, friction: 20 }
    }));
    const [sortExpanded, setSortExpanded] = useState(false);
    const [sortItem, setSortItem] = useState(Content.sorting.type.descending);
    const [updated, setUpdated] = useState(moment());

    const onBodyClick = evt => {
        if(evt.target.className.toString().includes('persist-callout')) {
            evt.preventDefault();
            return;
        }
        setShowCallout(false);
    }

    const onChangeSortOrder = key => {

        setSortItem(key);
        setSortExpanded(false);
        let { onChange } = search.sorting;
        if(typeof(onChange) === 'function') {
            onChange({
                sort_type: key,
                general: true
            });
        }
    }

    const onDownloadClick = async () => {
        try {
            let url = await download();
            window.open(url);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onOptionsClick = () => {
        setShowCallout(showCallout => !showCallout);
    }

    const onPanelSizeChange = () => {
        onSizeChange({
            width: contentContainer.current.clientWidth,
            height: contentContainer.current.clientHeight
        })
    }

    const onPrintClick = () => {

        // prevent moving forward if feature is disabled for current user
        if(print.permissions) {
            let match = print.permissions.find(key => utils.user.permissions.get(key) === false);
            if(match) {
                return utils.user.permissions.reject();
            }
        }

        // open print content layer
        utils.layer.open({
            id: 'print_content',
            Component: PrintContent.bind(this, {
                ...print,
                title: name
            })
        })
    }

    const onRefreshButtonClick = evt => {

        // end image animations
        refreshButtonAnimationsApi.stop();
        refreshButtonAnimationsApi({
            backgroundColor: Appearance.colors.grey(),
            transform: 'scale(1)'
        });

        // notify subscribers that a refresh has been requested
        let { onClick } = onRefreshContent || {};
        if(typeof(onClick) === 'function') {
            onClick();
        }
       
        // apply animation to element and remove after completion
        let target = evt.target;
        target.className = 'text-button rotate-once';
        setTimeout(() => {
            target.className = 'text-button';
        }, 2500);
    }

    const onSortClick = () => {
        setSortExpanded(expanded => !expanded);
    }

    const onSortExpandChange = () => {
        document.body.style.overflowY = sortExpanded ? 'hidden' : 'scroll';
        setSortContainer({ maxHeight: sortExpanded ? window.innerHeight / 3 : 0 });
    }

    const getButtons = () => {
        let next_buttons = [];
        if(buttons) {
            next_buttons = next_buttons.concat(buttons);
        }
        if(download) {
            next_buttons.push({
                key: 'download',
                title: 'Download',
                style: 'default',
                onClick: onDownloadClick
            })
        }
        if(print) {
            next_buttons.push({
                key: 'print',
                title: 'Print',
                style: 'default',
                onClick: onPrintClick
            })
        }
        return next_buttons;
    }

    const getButtonColor = item => {
        switch(item.style) {
            case 'cancel':
            return Appearance.colors.text();

            case 'destructive':
            return Appearance.colors.red;

            case 'default':
            return Appearance.colors.primary();

            default:
            return item.style || Appearance.colors.grey();
        }
    }

    const getCalloutButtonItems = () => {
        if(!buttons) {
            return null;
        }
        return buttons.concat([{
            key: 'close',
            title: 'Close',
            style: 'cancel',
            onClick: () => {
                setShowCallout(false);
            }
        }]).map((item, index, items) => {
            return (
                <div
                key={index}
                className={`sheet-item ${window.theme}`}
                onClick={e => {
                    e.preventDefault();
                    setShowCallout(false);
                    if(typeof(item.onClick) === 'function') {
                        item.onClick();
                    }
                }}
                style={{
                    borderTopLeftRadius: index === 0 ? 6 : 0,
                    borderTopRightRadius: index === 0 ? 6 : 0,
                    borderBottomLeftRadius: index === items.length - 1 ? 6 : 0,
                    borderBottomRightRadius: index === items.length - 1 ? 6 : 0,
                    overflow: 'hidden'
                }}>
                    <div
                    className={'persist-callout'}
                    style={{
                        alignItems: 'center',
                        borderTop: index === 0 ? null : `1px solid ${Appearance.colors.divider()}`,
                        display: 'flex',
                        height: 50,
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <span
                        className={'persist-callout'}
                        style={{
                            color: getButtonColor(item),
                            fontWeight: 400,
                            fontSize: 13,
                            textAlign: 'center',
                            width: '100%',
                        }}>{item.title}</span>
                    </div>
                </div>
            )
        });
    }

    const getCalloutButtons = () => {

        // return pre-determined content if applicable
        if(rightContent) {
            return rightContent;
        }

        // prepare button objects and determine if a refresh button should be provided
        let targets = getButtons();
        if(onRefreshContent && typeof(onRefreshContent.onClick) === 'function') {
            targets.push({ key: 'refresh' });
        }

        return targets.length > 0 && (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
            }}>
                {targets.filter(button => {
                    return button.visible !== false;
                }).map((button, index) => {

                    // return a refresh icon instead of an alt badge is applicable
                    if(button.key === 'refresh') {
                        return (
                            <animated.img
                            key={index}
                            className={`text-button ${onRefreshContent.className || ''}`}
                            onClick={onRefreshButtonClick}
                            src={'images/refresh-button-clear.png'}
                            title={'Refresh List'}
                            style={{
                                borderRadius: 15,
                                height: 30,
                                overflow: 'hidden',
                                width: 30,
                                ...onRefreshContent.style,
                                ...refreshButtonAnimations
                            }} />
                        )
                    }

                    // determine the background color for the button
                    let color = Appearance.colors.grey();
                    switch(button.style) {
                        case 'dark-grey':
                        color = Appearance.colors.darkGrey;
                        break;

                        case 'default':
                        color = Appearance.colors.primary();
                        break;

                        case 'destructive':
                        color = Appearance.colors.red;
                        break;

                        case 'grey':
                        color = Appearance.colors.grey();
                        break;

                        case 'secondary':
                        color = Appearance.colors.secondary();
                        break;

                        default:
                        color = button.style;
                    }

                    return (
                        <AltBadge
                        key={index}
                        onClick={button.onClick}
                        content={{
                            text: button.title,
                            color: color
                        }}
                        style={{
                            padding: '5px 16px 5px 16px'
                        }}/>
                    )
                })}
            </div>
        )
    }

    const getPanelName = () => {
        return total ? `${name} (${Utils.softNumberFormat(total)})` : name;
    }

    const getUpdatedDate = () => {
        let date = moment(updated);
        if(date.isSame(moment(), 'day')) {
            return date.format('[Today at] h:mma');
        }
        if(date.isSame(moment().subtract(1, 'days'), 'day')) {
            return date.format('[Yesterday at] h:mma');
        }
        return date.format('MMMM Do [at] h:mma');
    }

    const getSearchFields = () => {

        if(!search || !utils) {
            return null;
        }
        let user = utils.user.get();
        let { onChange, placeholder, props, sorting } = search;
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: 15,
                borderBottom: `1px solid ${Appearance.colors.divider()}`
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    {typeof(onChange) === 'function' && (
                        <>
                        <TextField
                        containerStyle={{flexGrow: 1}} 
                        icon={'search'}
                        onChange={onChange}
                        placeholder={placeholder || 'Search for something...'}
                        useDelay={true}/>
                        {getSearchAppendContent()}
                        </>
                    )}
                </div>
            </div>
        )
    }

    const getSearchAppendContent = () => {

        let { filters, sorting } = search;
        if(!filters && !sorting) {
            return null;
        }
        let items = [{
            key: Content.sorting.type.alphabetically,
            title: 'Sort Alphabetically',
            icon: 'sort-alphabetical'
        },{
            key: Content.sorting.type.descending,
            title: 'Order from Newest to Oldest',
            icon: 'sort-descending'
        },{
            key: Content.sorting.type.ascending,
            title: 'Order from Oldest to Newest',
            icon: 'sort-ascending'
        }];

        let sortMatch = items.find(item => item.key === sortItem);
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 8
            }}>
                {filters && (
                    <div style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative'
                    }}>
                        <div
                        className={'text-button'}
                        onClick={filters.onClick}
                        title={filters.values && Object.keys(filters.values).length > 0 ? 'Edit Filters' : 'Filters'}
                        style={{
                            backgroundColor: filters.values && Object.keys(filters.values).length > 0 ? Appearance.colors.primary() : Appearance.colors.grey(),
                            borderRadius: 15,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: 30,
                            marginLeft: 8,
                            minHeight: 30,
                            minWidth: 30,
                            overflow: 'hidden',
                            padding: 7.5,
                            width: 30
                        }}>
                            <img
                            src={'images/filter-icon-white.png'}
                            style={{
                                height: '100%',
                                objectFit: 'contain',
                                width: '100%'
                            }} />
                        </div>
                        {filters.values && Object.keys(filters.values).length > 0  && (
                            <img
                            className={'text-button'}
                            onClick={filters.onReset}
                            src={'images/prohibitory-red-small.png'}
                            title={'Reset Filters'}
                            style={{
                                height: 30,
                                marginLeft: 8,
                                objectFit: 'contain',
                                width: 30
                            }} />
                        )}
                    </div>
                )}
                {sorting && (
                    <div style={{
                        position: 'relative'
                    }}>
                        <div
                        className={'text-button'}
                        onClick={onSortClick}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 30,
                            height: 30,
                            minWidth: 30,
                            minHeight: 30,
                            padding: 7.5,
                            borderRadius: 15,
                            overflow: 'hidden',
                            marginLeft: 8,
                            backgroundColor: Appearance.colors.primary()
                        }}>
                            <img
                            src={sortMatch ? `images/${sortMatch.icon}-white.png` : null}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }} />
                        </div>
                        {sortExpanded && (
                            <div
                            onMouseEnter={() => document.body.style.overflowY = 'hidden'}
                            onMouseLeave={() => document.body.style.overflowY = 'scroll'}
                            style={{
                                ...Appearance.styles.panel(),
                                position: 'absolute',
                                right: -5,
                                top: 32,
                                width: 350,
                                overflowY: 'scroll',
                                borderWidth: 2,
                                borderColor: Appearance.colors.softBorder(),
                                backgroundColor: window.theme === 'dark' ? 'rgba(100,100,100,1)' : 'white',
                                boxShadow: window.theme === 'dark' ? '5px 5px 15px rgba(0,0,0,0.25)' : '5px 5px 15px rgba(175,175,174,0.25)',
                                maxHeight: window.innerHeight / 2,
                                zIndex: CalloutIndex
                            }}>
                                {items.filter(item => {
                                    return item.visible !== false;
                                }).map((item, index) => {
                                    return (
                                        <div
                                        key={index}
                                        className={`view-entry ${window.theme}`}
                                        onClick={onChangeSortOrder.bind(this, item.key)}
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: '100%',
                                            padding: 12,
                                            borderBottom: `${index !== items.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
                                        }}>
                                            <img
                                            src={`images/${item.icon}-${item.key === sortItem ? 'blue' : 'grey'}.png`}
                                            style={{
                                                minWidth: 18,
                                                minHeight: 18,
                                                width: 18,
                                                height: 18,
                                                objectFit: 'contain',
                                                marginRight: 12
                                            }} />
                                            <span style={{
                                                ...Appearance.textStyles.title(),
                                                color: item.key === sortItem ? Appearance.colors.primary() : Appearance.colors.text()
                                            }}>{item.title}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }

    const getSubTitle = () => {

        // determine if a custom string was provided for the subtitle
        if(typeof(subTitle) === 'string') {
            return (
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    lineHeight: 1.1,
                    maxWidth: '100%',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{subTitle}</span>
            )
        }

        // determine if a custom component was provided for the subtitle
        if(subTitle && subTitle.text) {
            return (
                <span 
                className={subTitle.onClick ? 'text-button' : ''}
                onClick={subTitle.onClick}
                style={{
                    ...Appearance.textStyles.subTitle(),
                    lineHeight: 1.1,
                    maxWidth: '100%',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    ...subTitle.style
                }}>{subTitle.text}</span>
            )
        }

        // fallback to showing a last update date and time based on when the child components were last altered
        return (
            <span style={{
                ...Appearance.textStyles.subTitle(),
                maxWidth: '100%',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                lineHeight: 1.1
            }}>{`Last Updated: ${getUpdatedDate()}`}</span>
        )
    }

    const runAnimations = async () => {
        try {
            await Utils.sleep(0.2 * index);
            setAnimations({
                top: 0,
                opacity: 1
            })
        } catch(e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        let elements = document.getElementsByClassName('panel');
        Array.from(elements).forEach(el => {
            if(showCallout) {
                el.addEventListener('click', onBodyClick);
                return;
            }
            el.removeEventListener('click', onBodyClick)
        })

    }, [showCallout]);

    const onRefreshContentPulseChange = () => {

        // determine if pulse animation is enabled or disabled
        let { color, enabled } = onRefreshContent.pulse;
        if(enabled === false) {
            refreshButtonAnimationsApi.stop();
            refreshButtonAnimationsApi({
                backgroundColor: Appearance.colors.grey(),
                transform: 'scale(1)'
            });
        }
        if(enabled === true) {
            refreshButtonAnimationsApi({
                loop: true,
                from: {
                    backgroundColor: Appearance.colors.grey(),
                    transform: 'scale(1)'
                },
                to: async (next, cancel) => {
                    if(enabled === false) {
                        cancel();
                        return;
                    }
                    await next({
                        backgroundColor: color,
                        transform: 'scale(1.2)'
                    });
                    await next({
                        backgroundColor: Appearance.colors.grey(),
                        transform: 'scale(1)'
                    });
                }
            });
        }
    }

    useEffect(() => {
        if(onRefreshContent && onRefreshContent.pulse) {
            onRefreshContentPulseChange();
        }
    }, [onRefreshContent]);

    useEffect(() => {
        if(contentContainer.current && typeof(onSizeChange) === 'function') {
            onPanelSizeChange();
            window.addEventListener('resize', onPanelSizeChange);
            return () => {
                window.removeEventListener('resize', onPanelSizeChange);
            }
        }
    }, [contentContainer.current]);

    useEffect(() => {
        setUpdated(moment());
    }, [children]);

    useEffect(() => {
        onSortExpandChange();
    }, [sortExpanded]);

    useEffect(() => {
        runAnimations();
    }, []);

    return (
        <animated.div
        className={`panel window ${className || `${column || 'col-12'} p-3`}`}
        style={{
            position: 'relative',
            ...animations
        }}>
            <div style={{
                position: 'relative',
                width: '100%',
                maxWidth: maxWidth,
                paddingTop: name && Utils.isMobile() ? 40 : 0,
                ...style
            }}>
                {/* mobiles panel title */}
                {name && Utils.isMobile() && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        left: 15,
                        right: 15,
                        height: 43,
                        paddingLeft: 12,
                        paddingRight: 12,
                        backgroundColor: Appearance.colors.panelMobileTabBackground(),
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        border: `4px solid ${window.theme === 'dark' ? 'rgba(25,25,25,1)':'white'}`,
                        textAlign: 'center'
                    }}>
                        <span style={{
                            ...Appearance.textStyles.panelTitle()
                        }}>{getPanelName()}</span>
                    </div>
                )}

                {/* panel container */}
                <div style={{
                    height: '100%',
                    ...Appearance.styles.panel()
                }}>
                    {/* hide top bar if mobile */}
                    <div style={{
                        ...Appearance.styles.header(),
                        display: Utils.isMobile() || !name ? 'none' : 'block',
                        ...headerStyle
                    }}>
                        <div style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexGrow: 1,
                                alignItems: 'center'
                            }}>
                                <img
                                src={'images/global_data_system_user.png'}
                                style={{
                                    width: 30,
                                    height: 30,
                                    objectFit: 'contain',
                                    marginRight: 12
                                }} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <span style={{
                                        color: Appearance.colors.text(),
                                        fontSize: 16,
                                        fontWeight: 700,
                                        maxWidth: '100%',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        lineHeight: 1.1,
                                        marginBottom: 2
                                    }}>{getPanelName()}</span>
                                    {getSubTitle()}
                                </div>
                            </div>
                            {getCalloutButtons(true)}
                        </div>
                    </div>
                    <div className={'card-body p-0'}>
                        {loading && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0
                            }}>
                                <ProgressBar/>
                            </div>
                        )}
                        {getSearchFields()}
                        <div
                        ref={contentContainer}
                        className={`card-body-content`}
                        style={{
                            padding: removePadding ? 0 : 15,
                            overflowY: removeOverflow === true ? 'visible':'scroll'
                        }}>
                            {children}
                        </div>
                    </div>
                    <PageControl {...paging} />
                </div>
            </div>
        </animated.div>
    )
}
export default Panel;
