import React, { useRef, useState, useEffect } from 'react';

import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment-timezone';
import update from 'immutability-helper';

import API from 'files/api.js';
import Abstract from 'classes/Abstract.js';
import AltFieldMapper, { validateRequiredFields } from 'views/AltFieldMapper.js';
import Appearance from 'styles/Appearance.js';
import Content from 'managers/Content.js';
import FieldMapper, { formatFields } from 'views/FieldMapper.js';
import Layer, { LayerItem } from 'structure/Layer.js';
import Lead from 'classes/Lead.js';
import { LeadDetails } from 'managers/Leads.js';
import LeadScriptEditor from 'views/LeadScriptEditor.js';
import LeadLookupField from 'views/LeadLookupField.js';
import ListField from 'views/ListField.js';
import PageControl from 'views/PageControl.js';
import Panel from 'structure/Panel.js';
import Program from 'classes/Program.js';
import QuestionEditor, { QuestionsContainer } from 'views/QuestionEditor.js';
import Request from 'files/Request.js';
import RichTextEditor from 'views/RichTextEditor.js';
import TableListHeader from 'views/TableListHeader.js';
import TextField from 'views/TextField.js';
import User from 'classes/User.js';
import { UserDetails } from 'managers/Users.js';
import UserLookupField from 'views/UserLookupField.js';
import Utils from 'files/Utils.js';
import Views from 'views/Main.js';

// Panels
export const LeadProgramEnrollment = ({ index, options, utils }) => {

    const panelID = 'lead_program_enrollment';
    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [entries, setEntries] = useState([]);

    const [sortOrder, setSortOrder] = useState({
        key: 'date',
        forward: false
    });

    const getFields = (entry, index) => {

        let target = entry || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.name,
                    subTitle: target.description,
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onEntryClick.bind(this, entry)
                })
            )
        }

        let fields = [{
            key: 'start_date',
            title: 'Registered',
            value: target.start_date ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title()
                    }}>{moment(target.start_date).format('MMM Do')}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{moment(target.start_date).format('h:mma')}</span>
                </div>
            ) : null
        },{
            key: 'program',
            title: 'Program',
            value: target.program ? target.program.name : null
        },{
            key: 'lead',
            title: 'Lead',
            value: target.lead ? target.lead.full_name : null
        },{
            key: 'dealership',
            title: 'Dealership',
            visible: utils.user.get().level <= User.levels.get().admin,
            value: target.program && target.program.dealership ? target.program.dealership.name : 'All Dealerships'
        },{
            key: 'end_date',
            title: 'End Date',
            value: target.end_date ? moment(target.end_date).format('MMM Do, YYYY') : 'Open Ended'
        },{
            key: 'status',
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // Headers
        if(!entry) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== entries.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onEntryClick.bind(this, entry)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getActiveStatus = entry => {
        if(!entry) {
            return;
        }
        let color = entry.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, entry)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{entry.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const onChangeActiveStatus = async (entry, e) => {
        try {
            e.stopPropagation();
            await Request.post(utils, '/programs/', {
                type: 'set_enrollment_active_status',
                id: entry.id,
                active: !entry.active
            });

            setEntries(entries => {
                return entries.map(prevEntry => {
                    if(prevEntry.id === entry.id) {
                        prevEntry.active = !prevEntry.active;
                    }
                    return prevEntry;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this enrollment. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintEnrollment,
            onRenderItem: (item, index, items) => ({
                start_date: moment(item.start_date).format('MMMM Do, YYYY [at] h:mma'),
                program: item.program.name,
                lead: item.lead.full_name,
                dealership: item.program && item.program.dealership ? item.program.dealership.name : 'All Dealerships',
                end_date: item.end_date ? moment(item.end_date).format('MMM Do, YYYY') : 'Open Ended',
                status: item.active ? 'Active' : 'Not Active'
            }),
            headers: [{
                key: 'start_date',
                title: 'Registered'
            },{
                key: 'program',
                title: 'Program'
            },{
                key: 'lead',
                title: 'Lead'
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'end_date',
                title: 'End Date'
            },{
                key: 'status',
                title: 'Status'
            }]
        }
    }

    const onPrintEnrollment = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { entries } = await Request.get(utils, '/programs/', {
                    search_text: searchText,
                    type: 'all_lead_enrollment',
                    ...props
                });

                setLoading(false);
                resolve(entries.map(entry => ({
                    ...entry,
                    lead: Lead.create(entry.lead),
                    program: Program.create(entry.program)
                })));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const fetchEnrollment = async () => {
        try {
            setLoading(true);
            let { entries, paging } = await Request.get(utils, '/programs/', {
                type: 'all_lead_enrollment',
                limit: 10,
                offset: offset
            });

            setLoading(false);
            setPaging(paging);
            setSearchOffset(0);
            setEntries(entries.map(entry => ({
                ...entry,
                lead: Lead.create(entry.lead),
                program: Program.create(entry.program)
            })))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the lead program enrollment list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const searchEnrollment = async () => {
        if(!searchText) {
            return;
        }
        try {
            setLoading(true);
            let { entries, paging } = await Request.get(utils, '/programs/', {
                limit: 10,
                offset: searchOffset,
                search_text: searchText,
                type: 'all_lead_enrollment'
            });

            setLoading(false);
            setOffset(0);
            setPaging(paging);
            setEntries(entries.map(entry => ({
                ...entry,
                lead: Lead.create(entry.lead),
                program: Program.create(entry.program)
            })))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the lead program enrollment list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEntryClick = entry => {
        utils.sheet.show({
            items: [{
                key: 'program',
                title: 'View Program',
                style: 'default'
            },{
                key: 'lead',
                title: 'View Lead',
                style: 'default'
            }]
        }, async key => {
            if(key === 'lead') {
                try {
                    let lead = await Lead.get(utils, entry.lead.id);
                    utils.layer.open({
                        abstract: Abstract.create({
                            object: lead,
                            type: 'lead'
                        }),
                        Component: LeadDetails,
                        id: `lead_details_${entry.lead.id}`,
                        permissions: ['leads.details']
                    });
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }
            if(key === 'program') {
                try {
                    let program = await Program.get(utils, entry.program.id);
                    utils.layer.open({
                        abstract: Abstract.create({
                            object: program,
                            type: 'program'
                        }),
                        Component: ProgramDetails,
                        id: `program_details_${entry.program.id}`,
                        permissions: ['programs.details']
                    });
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving the information for this Program. ${e.message || 'An unknown error occurred'}`
                    })
                }
                return;
            }
        })
    }

    const getContent = () => {
        if(entries.length === 0) {
            return (
                Views.entry({
                    title: 'No Enrollment Entries Found',
                    subTitle: 'No enrollment entries were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {entries.map((entry, index) => {
                        return getFields(entry, index)
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchEnrollment();
    }, [offset]);

    useEffect(() => {
        searchEnrollment();
    }, [searchOffset]);

    useEffect(() => {
        if(searchText) {
            searchEnrollment();
        } else {
            fetchEnrollment();
        }
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchEnrollment);
        utils.content.subscribe(panelID, ['lead', 'lead_status', 'program'], {
            onFetch: fetchEnrollment,
            onUpdate: abstract => {
                setEntries(entries => {
                    return entries.map(entry => {
                        switch(abstract.type) {
                            case 'lead':
                            if(entry.lead.id === abstract.getID()) {
                                entry.lead.status = abstract.object.status;
                            }
                            return entry;

                            case 'lead':
                            return entry.lead.id === abstract.getID() ? { ...entry, lead: abstract.object } : entry;

                            case 'program':
                            return entry.program.id === abstract.getID() ? { ...entry, program: abstract.object } : entry;
                        }
                        return entry;
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchEnrollment);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Lead Program Enrollment'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by Program or Lead name...',
                onChange: text => setSearchText(text)
            },
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: 10,
                offset: searchText ? searchOffset : offset,
                onClick: nextOffset => {
                    if(searchText) {
                        setSearchOffset(nextOffset);
                        return;
                    }
                    setOffset(nextOffset);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const Programs = ({ id, props, title }, { index, options, utils }) => {

    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [searchText, setSearchText] = useState(null);
    const [sorting, setSorting] = useState(null);

    const getActiveStatus = program => {
        if(!program) {
            return;
        }

        // determine whether or not editing is enabled for this program
        let { dealership, level } = utils.user.get();
        let canEdit = level === User.levels.get().admin || (program.dealership_id === dealership.id) ? true : false;

        // return plain text if editing is not enabled for this program
        if(canEdit === false) {
            return (
                <span>{program.active ? 'Active' : 'Not Active'}</span>
            )
        }

        // return status button with option to change status on click
        let color = program.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            {...canEdit && {
                className: 'text-button',
                onClick: onChangeActiveStatus.bind(this, program)
            }}
            style={{
                alignItems: 'center',
                background: Appearance.colors.softGradient(color),
                border: `1px solid ${color}`,
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                maxWidth: 75,
                overflow: 'hidden',
                textAlign: 'center',
                width: 100
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{program.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const onChangeActiveStatus = async (program, e) => {
        try {
            e.stopPropagation();
            await Request.post(utils, '/programs/', {
                type: 'set_active_status',
                id: program.id,
                active: !program.active
            });

            setPrograms(programs => {
                return programs.map(prevProgram => {
                    if(prevProgram.id === program.id) {
                        prevProgram.active = !prevProgram.active;
                    }
                    return prevProgram;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status for this Program. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getEndDate = program => {
        if(!program) {
            return null;
        }
        if(!program.end_date && !program.end_days) {
            return 'Open Ended';
        }
        if(program.end_days) {
            return `${program.end_days} ${program.end_days === 1 ? 'day':'days'} after signup`
        }
        let diff = moment(program.end_date).diff(moment(program.start_date), 'days');
        if(diff > 0 && diff < 31) {
            return `${diff} ${diff === 1 ? 'day':'days'}`
        }
        return moment(program.end_date).format('MM/DD/YYYY')
    }

    const onNewProgram = () => {

        let program = Program.new();
        program.allow_sharing = props.allow_sharing;

        utils.layer.open({
            abstract: Abstract.create({
                object: program,
                type: 'program'
            }),
            Component: AddEditProgram.bind(this, {
                isNewTarget: true
            }),
            id: 'new_program',
            permissions: ['programs.actions.new']
        })
    }

    const onPrintPrograms = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { programs } = await Request.get(utils, '/programs/', {
                    type: searchText ? 'lookup' : 'all',
                    search_text: searchText,
                    ...sorting,
                    ...props
                });

                setLoading(false);
                resolve(programs.map(program => Program.create(program)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const onProgramClick = async id => {
        try {

            // set loading flag and fetch program details
            setLoading(true);
            let program = await Program.get(utils, id);

            // end loading and show program details layer
            setLoading(false);
            utils.layer.open({
                abstract: Abstract.create({
                    object: program,
                    type: 'program'
                }),
                Component: ProgramDetails,
                id: `program_details_${id}`,
                permissions: ['programs.details']
            });
        
        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retreiving the details for this program. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getContent = () => {
        if(programs.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No programs were found in the system',
                    title: 'No Programs Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {programs.map((program, index) => {
                        return getFields(program, index)
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (program, index) => {

        let target = program || {};
        let fields = [{
            key: 'name',
            title: 'Name',
            value: target.name
        },{
            key: 'description',
            title: 'Description',
            value: target.description
        },{
            key: 'end_date',
            title: 'Ends',
            sortable: false,
            value: getEndDate(program)
        },{
            key: 'registered',
            title: 'Registered',
            value: target.registered || 0
        },{
            key: 'status',
            title: 'Status',
            sortable: false,
            value: getActiveStatus(program)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!program) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'name',
                [Content.sorting.type.ascending]: 'created',
                [Content.sorting.type.descending]: 'created'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== programs.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onProgramClick.bind(this, program.id)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintPrograms,
            onRenderItem: (item, index, items) => ({
                name: item.name,
                description: item.description,
                end_date: getEndDate(item),
                registered: item.registered || 0,
                status: getActiveStatus(item)
            }),
            headers: [{
                key: 'name',
                title: 'Name'
            },{
                key: 'description',
                title: 'Description'
            },{
                key: 'end_date',
                title: 'Ends'
            },{
                key: 'registered',
                title: 'Registered'
            },{
                key: 'status',
                title: 'Status'
            }]
        }
    }

    const fetchPrograms = async () => {
        try {
            setLoading(true);
            let { programs, paging } = await Request.get(utils, '/programs/', {
                type: 'all',
                limit: limit,
                offset: offset,
                search_text: searchText,
                ...props,
                ...sorting
            });

            setLoading(false);
            setPaging(paging);
            setPrograms(programs.map(program => Program.create(program)))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the programs list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchPrograms();
    }, [offset, searchText, sorting]);

    useEffect(() => {
        utils.events.on(id, 'dealership_change', fetchPrograms);
        utils.content.subscribe(id, [ 'program' ], {
            onFetch: fetchPrograms,
            onUpdate: abstract => {
                setPrograms(programs => {
                    return programs.map(program => {
                        return program.id === abstract.getID() ? abstract.object : program
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(id);
            utils.events.off(id, 'dealership_change', fetchPrograms);
        }
    }, []);

    return (
        <Panel
        panelID={id}
        name={`${title}s`}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: `Search by ${title} name...`,
                onChange: setSearchText
            },
            buttons: [{
                key: 'new',
                title: `New ${title}`,
                style: 'default',
                onClick: onNewProgram
            }],
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: setOffset
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const ProgramAgreementTemplates = ({ index, options, utils }) => {

    const panelID = 'program_agreement_templates';
    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sorting, setSorting] = useState(null);
    const [templates, setTemplates] = useState([]);

    const onNewTemplate = () => {
        utils.layer.open({
            id: 'new_program_agreement_template',
            abstract: Abstract.create({
                type: 'program_agreement_template',
                object: Program.Agreement.Template.new()
            }),
            Component: AddEditProgramAgreementTemplate.bind(this, {
                isNewTarget: true
            })
        })
    }

    const onTemplateClick = template => {
        utils.layer.open({
            id: `program_agreement_template_details_${template.id}`,
            abstract: Abstract.create({
                type: 'program_agreement_template',
                object: template
            }),
            Component: ProgramAgreementTemplateDetails
        })
    }

    const getContent = () => {
        if(templates.length === 0) {
            return (
                Views.entry({
                    title: 'No Templates Found',
                    subTitle: 'No templates were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return templates.map((template, index) => {
                return getFields(template, index);
            });
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {templates.map((template, index) => {
                        return getFields(template, index);
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (template, index) => {

        let target = template || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.title,
                    subTitle: target.description,
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onTemplateClick.bind(this, template)
                })
            )
        }

        let fields = [{
            key: 'title',
            title: 'Title',
            value: target.title
        },{
            key: 'date',
            title: 'Created',
            value: Utils.formatDate(target.date)
        },{
            key: 'used_by',
            title: 'Used By',
            value: `${target.used_by} ${target.used_by === 1 ? 'program' : 'programs'}`
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!template) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'title',
                [Content.sorting.type.ascending]: 'date',
                [Content.sorting.type.descending]: 'date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== templates.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onTemplateClick.bind(this, template)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const fetchTemplates = async () => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            setLoading(true);
            utils.fetching.set(panelID, true);
            let { templates, paging } = await Request.get(utils, '/programs/', {
                type: 'agreement_templates',
                limit: limit,
                offset: offset,
                search_text: searchText,
                ...sorting
            });

            setLoading(false);
            utils.fetching.set(panelID, false);

            setPaging(paging);
            setTemplates(templates.map(template => Program.Agreement.Template.create(template)))

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the templates list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTemplates();
    }, [offset, searchText, sorting]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchTemplates);
        utils.content.subscribe(panelID, ['program_agreement_template'], {
            onFetch: fetchTemplates,
            onUpdate: abstract => {
                setTemplates(templates => {
                    return templates.map(template => {
                        return template.id === abstract.getID() ? abstract.object : template
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchTemplates);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Rules and Disclaimer Templates'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            search: {
                placeholder: 'Search by template title...',
                onChange: setSearchText
            },
            buttons: [{
                key: 'new',
                title: 'New Template',
                style: 'default',
                onClick: onNewTemplate
            }],
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: nextOffset => setOffset(nextOffset)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const ProgramTemplates = ({ index, options, utils }) => {

    const panelID = 'program_templates';
    const limit = 15;

    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [sorting, setSorting] = useState(null);
    const [templates, setTemplates] = useState([]);

    const getActiveStatus = template => {

        if(!template) {
            return;
        }

        // determine whether or not editing is enabled for this template
        let { dealership, level } = utils.user.get();
        let canEdit = level === User.levels.get().admin || (template.dealership_id === dealership.id) ? true : false;

        // return plain text if editing is not enabled for this program
        if(canEdit === false) {
            return (
                <span>{template.active ? 'Active' : 'Not Active'}</span>
            )
        }

        let color = template.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, template)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{template.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const onChangeActiveStatus = async (template, e) => {
        try {
            e.stopPropagation();
            await Request.post(utils, '/programs/', {
                type: 'set_template_active_status',
                id: template.id,
                active: !template.active
            });
            setTemplates(templates => {
                return templates.map(prevTemplate => {
                    if(prevTemplate.id === template.id) {
                        prevTemplate.active = !prevTemplate.active;
                    }
                    return prevTemplate;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this template. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onNewTemplate = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: Program.Template.new(),
                type: 'template'
            }),
            Component: AddEditTemplate.bind(this, {
                isNewTarget: true
            }),
            id: 'new_template',
            permissions: ['programs.templates.actions.new']
        })
    }

    const onPrintTemplates = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { templates } = await Request.get(utils, '/programs/', {
                    type: 'templates',
                    search_text: searchText,
                    ...sorting,
                    ...props
                });

                setLoading(false);
                resolve(templates.map(template => Program.Template.create(template)));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        });
    }

    const onTemplateClick = template => {
        utils.layer.open({
            abstract: Abstract.create({
                object: template,
                type: 'template'
            }),
            Component: TemplateDetails,
            id: `template_details_${template.id}`,
            permissions: ['programs.templates.details']
        });
    }

    const getContent = () => {
        if(templates.length === 0) {
            return (
                Views.entry({
                    bottomBorder: false,
                    hideIcon: true,
                    subTitle: 'No templates were found in the system',
                    title: 'No Templates Found'
                })
            )
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {templates.map((template, index) => {
                        return getFields(template, index);
                    })}
                </tbody>
            </table>
        )
    }

    const getFields = (template, index) => {

        let target = template || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.title,
                    subTitle: target.description,
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onTemplateClick.bind(this, template)
                })
            )
        }

        let fields = [{
            key: 'title',
            title: 'Title',
            value: target.title
        },{
            key: 'description',
            title: 'Description',
            value: target.description
        },{
            key: 'used_by',
            title: 'Used By',
            value: `${target.used_by} ${target.used_by === 1 ? 'program':'programs'}`
        },{
            key: 'questions',
            title: 'Questions',
            value: target.questions ? `${target.questions.length} ${target.questions.length === 1 ? 'question':'questions'}` : null
        },{
            key: 'request_demo',
            title: 'Request a Demo',
            value: target.request_demo ? 'Yes' : 'No'
        },{
            key: 'status',
            title: 'Status',
            sortable: false,
            value: getActiveStatus(target)
        }];

        // create table headers with custom sorting options
        // conform external sort to match internal header sort if applicable
        if(!template) {
            let sorts = {
                [Content.sorting.type.alphabetically]: 'title',
                [Content.sorting.type.ascending]: 'date',
                [Content.sorting.type.descending]: 'date'
            };
            return (
                <TableListHeader
                fields={fields}
                onChange={props => setSorting(props)}
                {...sorting && sorting.general === true && {
                    value: {
                        key: sorts[sorting.sort_type],
                        direction: sorting.sort_type
                    }
                }} />
            )
        }

        // loop through result rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== templates.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onTemplateClick.bind(this, template)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintTemplates,
            onRenderItem: (item, index, items) => {
                return {
                    title: item.title,
                    description: item.description,
                    used_by: `${item.used_by} ${item.used_by === 1 ? 'program' : 'programs'}`,
                    questions: item.questions ? `${item.questions.length} ${item.questions.length === 1 ? 'question':'questions'}` : null,
                    request_demo: item.request_demo ? 'Yes' : 'No',
                    status: getActiveStatus(item)
                }
            },
            headers: [{
                key: 'title',
                title: 'Title'
            },{
                key: 'description',
                title: 'Description'
            },{
                key: 'used_by',
                title: 'Used By'
            },{
                key: 'questions',
                title: 'Questions'
            },{
                key: 'request_demo',
                title: 'Request a Demo'
            },{
                key: 'status',
                title: 'Status'
            }]
        }
    }

    const fetchTemplates = async () => {
        try {
            if(utils.fetching.get(panelID) === true) {
                return;
            }
            setLoading(true);
            utils.fetching.set(panelID, true);
            let { templates, paging } = await Request.get(utils, '/programs/', {
                type: 'templates',
                limit: limit,
                offset: offset,
                search_text: searchText,
                ...sorting
            });

            setLoading(false);
            utils.fetching.set(panelID, false);

            setPaging(paging);
            setTemplates(templates.map(template => Program.Template.create(template)))

        } catch(e) {
            setLoading(false);
            utils.fetching.set(panelID, false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the templates list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        fetchTemplates();
    }, [offset, searchText, sorting]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchTemplates);
        utils.content.subscribe(panelID, ['template'], {
            onFetch: fetchTemplates,
            onUpdate: abstract => {
                setTemplates(templates => {
                    return templates.map(template => {
                        return template.id === abstract.getID() ? abstract.object : template
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchTemplates);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Templates'}
        index={index}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            search: {
                placeholder: 'Search by template title...',
                onChange: setSearchText
            },
            buttons: [{
                key: 'new',
                title: 'New Template',
                style: 'default',
                onClick: onNewTemplate
            }],
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: limit,
                offset: offset,
                onClick: nextOffset => setOffset(nextOffset)
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

export const UserProgramEnrollment = ({ index, options, utils }) => {

    const panelID = 'user_program_enrollment';
    const [loading, setLoading] = useState(null);
    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [entries, setEntries] = useState([]);

    const [sortOrder, setSortOrder] = useState({
        key: 'date',
        forward: false
    });

    const getFields = (entry, index) => {

        let target = entry || {};
        if(Utils.isMobile()) {
            return (
                Views.entry({
                    key: index,
                    title: target.name,
                    subTitle: target.description,
                    hideIcon: true,
                    bottomBorder: true,
                    onClick: onEntryClick.bind(this, entry)
                })
            )
        }

        let fields = [{
            key: 'start_date',
            title: 'Registered',
            value: target.start_date ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title()
                    }}>{moment(target.start_date).format('MMM Do')}</span>
                    <span style={{
                        ...Appearance.textStyles.subTitle()
                    }}>{moment(target.start_date).format('h:mma')}</span>
                </div>
            ) : null
        },{
            key: 'program',
            title: 'Program',
            value: target.program ? target.program.name : null
        },{
            key: 'user',
            title: 'Safety Associate',
            value: target.user ? target.user.full_name : null
        },{
            key: 'dealership',
            title: 'Dealership',
            visible: utils.user.get().level <= User.levels.get().admin,
            value: target.program && target.program.dealership ? target.program.dealership.name : 'All Dealerships'
        },{
            key: 'end_date',
            title: 'End Date',
            value: target.end_date ? Utils.formatDate(target.end_date) : 'Open Ended'
        },{
            key: 'status',
            title: 'Status',
            value: getActiveStatus(target)
        }];

        // Headers
        if(!entry) {
            return (
                <tr style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`
                }}>
                {fields.map((field, index) => {
                    return (
                        <td
                        key={index}
                        className={'px-3 py-2 flexible-table-column'}>
                            <span style={{
                                ...Appearance.textStyles.title()
                            }}>{field.title}</span>
                        </td>
                    )
                })}
                </tr>
            )
        }

        // Rows
        return (
            <tr
            key={index}
            className={`view-entry ${window.theme}`}
            style={{
                borderBottom: `${index !== entries.length - 1 ? 1 : 0}px solid ${Appearance.colors.divider()}`
            }}>
            {fields.map((field, index) => {
                return (
                    <td
                    key={index}
                    className={'px-3 py-2 flexible-table-column'}
                    onClick={onEntryClick.bind(this, entry)}>
                        <span style={Appearance.textStyles.subTitle()}>{field.value}</span>
                    </td>
                )
            })}
            </tr>
        )
    }

    const getActiveStatus = entry => {
        if(!entry) {
            return;
        }
        let color = entry.active ? Appearance.colors.primary() : Appearance.colors.grey();
        return (
            <div
            className={'text-button'}
            onClick={onChangeActiveStatus.bind(this, entry)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 100,
                height: '100%',
                maxWidth: 75,
                textAlign: 'center',
                border: `1px solid ${color}`,
                background: Appearance.colors.softGradient(color),
                borderRadius: 5,
                overflow: 'hidden'
            }}>
                <span style={{
                    ...Appearance.textStyles.subTitle(),
                    color: 'white',
                    fontWeight: '600',
                    width: '100%'
                }}>{entry.active ? 'Active' : 'Not Active'}</span>
            </div>
        )
    }

    const onChangeActiveStatus = async (entry, e) => {
        try {
            e.stopPropagation();
            await Request.post(utils, '/programs/', {
                type: 'set_enrollment_active_status',
                id: entry.id,
                active: !entry.active
            });

            setEntries(entries => {
                return entries.map(prevEntry => {
                    if(prevEntry.id === entry.id) {
                        prevEntry.active = !prevEntry.active;
                    }
                    return prevEntry;
                })
            })

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this enrollment. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getPrintProps = () => {
        return {
            onFetch: onPrintEnrollment,
            onRenderItem: item => ({
                start_date: moment(item.start_date).format('MMMM Do, YYYY [at] h:mma'),
                program: item.program.name,
                user: item.user.full_name,
                dealership: item.program && item.program.dealership ? item.program.dealership.name : 'All Dealerships',
                end_date: item.end_date ? moment(item.end_date).format('MMM Do, YYYY') : 'Open Ended',
                status: item.active ? 'Active' : 'Not Active'
            }),
            headers: [{
                key: 'start_date',
                title: 'Registered'
            },{
                key: 'program',
                title: 'Program'
            },{
                key: 'user',
                title: 'Safety Associate'
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin
            },{
                key: 'end_date',
                title: 'End Date'
            },{
                key: 'status',
                title: 'Status'
            }]
        }
    }

    const onPrintEnrollment = async props => {
        return new Promise(async (resolve, reject) => {
            try {
                setLoading(true);
                let { entries } = await Request.get(utils, '/programs/', {
                    search_text: searchText,
                    type: 'all_user_enrollment',
                    ...props
                });

                setLoading(false);
                resolve(entries.map(entry => ({
                    ...entry,
                    user: User.create(entry.user),
                    program: Program.create(entry.program)
                })));

            } catch(e) {
                setLoading(false);
                reject(e);
            }
        })
    }

    const fetchEnrollment = async () => {
        try {
            setLoading(true);
            let { entries, paging } = await Request.get(utils, '/programs/', {
                type: 'all_user_enrollment',
                limit: 10,
                offset: offset
            });

            setLoading(false);
            setPaging(paging);
            setSearchOffset(0);
            setEntries(entries.map(entry => ({
                ...entry,
                user: User.create(entry.user),
                program: Program.create(entry.program)
            })))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the lead program enrollment list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const searchEnrollment = async () => {
        if(!searchText) {
            return;
        }
        try {
            setLoading(true);
            let { entries, paging } = await Request.get(utils, '/programs/', {
                limit: 10,
                offset: searchOffset,
                search_text: searchText,
                type: 'all_user_enrollment'
            });

            setLoading(false);
            setOffset(0);
            setPaging(paging);
            setEntries(entries.map(entry => ({
                ...entry,
                user: User.create(entry.user),
                program: Program.create(entry.program)
            })))

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading the lead program enrollment list. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEntryClick = entry => {
        utils.sheet.show({
            items: [{
                key: 'program',
                title: 'View Program',
                style: 'default'
            },{
                key: 'user',
                title: 'View Safety Associate',
                style: 'default'
            }]
        }, async key => {
            if(key === 'user') {
                try {
                    let user = await User.get(utils, entry.user.user_id);
                    utils.layer.open({
                        abstract: Abstract.create({
                            object: user,
                            type: 'user'
                        }),
                        Component: UserDetails,
                        id: `user_details_${entry.user.user_id}`,
                        permissions: ['users.details']
                    });
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving the information for this lead. ${e.message || 'An unknown error occurred'}`
                    });
                }
                return;
            }
            if(key === 'program') {
                try {
                    let program = await Program.get(utils, entry.program.id);
                    utils.layer.open({
                        abstract: Abstract.create({
                            object: program,
                            type: 'program'
                        }),
                        Component: ProgramDetails,
                        id: `program_details_${entry.program.id}`,
                        permissions: ['programs.details']
                    });
                } catch(e) {
                    utils.alert.show({
                        title: 'Oops!',
                        message: `There was an issue retrieving the information for this Program. ${e.message || 'An unknown error occurred'}`
                    });
                }
                return;
            }
        })
    }

    const getContent = () => {
        if(entries.length === 0) {
            return (
                Views.entry({
                    title: 'No Enrollment Entries Found',
                    subTitle: 'No enrollment entries were found in the system',
                    bottomBorder: false,
                    hideIcon: true
                })
            )
        }
        if(Utils.isMobile()) {
            return entries.map((entry, index) => {
                return getFields(entry, index)
            })
        }
        return (
            <table
            className={'px-3 py-2 m-0'}
            style={{
                width: '100%'
            }}>
                <thead style={{
                    width: '100%'
                }}>
                    {getFields()}
                </thead>
                <tbody style={{
                    width: '100%'
                }}>
                    {entries.map((entry, index) => {
                        return getFields(entry, index)
                    })}
                </tbody>
            </table>
        )
    }

    useEffect(() => {
        fetchEnrollment();
    }, [offset]);

    useEffect(() => {
        searchEnrollment();
    }, [searchOffset]);

    useEffect(() => {
        if(searchText) {
            searchEnrollment();
        } else {
            fetchEnrollment();
        }
    }, [searchText]);

    useEffect(() => {
        utils.events.on(panelID, 'dealership_change', fetchEnrollment);
        utils.content.subscribe(panelID, ['user', 'program'], {
            onFetch: fetchEnrollment,
            onUpdate: abstract => {
                setEntries(entries => {
                    return entries.map(entry => {
                        switch(abstract.type) {
                            case 'lead':
                            return entry.program.id === abstract.getID() ? { ...entry, program: abstract.object } : entry;

                            case 'user':
                            return entry.user.user_id === abstract.getID() ? { ...entry, user: abstract.object } : entry;
                        }
                        return entry;
                    })
                })
            }
        });
        return () => {
            utils.content.unsubscribe(panelID);
            utils.events.off(panelID, 'dealership_change', fetchEnrollment);
        }
    }, []);

    return (
        <Panel
        panelID={panelID}
        name={'Safety Associate Program Enrollment'}
        index={index}
        utils={utils}
        options={{
            loading: loading,
            search: {
                placeholder: 'Search by Program or Safety Associate name...',
                onChange: text => setSearchText(text)
            },
            print: getPrintProps(),
            paging: {
                data: paging,
                limit: 10,
                offset: searchText ? searchOffset : offset,
                onClick: nextOffset => {
                    if(searchText) {
                        setSearchOffset(nextOffset);
                        return;
                    }
                    setOffset(nextOffset);
                }
            }
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel()
            }}>
                {getContent()}
            </div>
        </Panel>
    )
}

// Layers
export const AddEditProgram = ({ isNewTarget, programAgreementTemplate }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_program` : `edit_program_${abstract.getID()}`;
    const [agreementTemplate, setAgreementTemplate] = useState(programAgreementTemplate);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [program, setProgram] = useState(null);
    const [scripts, setScripts] = useState([]);
    const [templates, setTemplates] = useState([]);

    const onDoneClick = async () => {

        try {
            setLoading('done');
            await Utils.sleep(0.25);
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {
                await abstract.object.submit(utils, { agreement_template_id: agreementTemplate ? agreementTemplate.id : null });
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your new ${getLabel()} has been created`,
                    onClick: () => setLayerState('close')
                });
                return;
            }

            // update target
            await abstract.object.update(utils, { agreement_template_id: agreementTemplate ? agreementTemplate.id : null });
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This ${getLabel()} has been updated`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this ${getLabel()}. ${e.message || 'An unknown error occurred'}`
            })
        }
        return;
    }

    const onNewTemplate = async () => {
        try {
            utils.layer.requestClose('edit_target');
            await Utils.sleep(0.5);

            utils.layer.open({
                abstract: Abstract.create({
                    object: Program.Template.new(),
                    type: 'template'
                }),
                Component: AddEditTemplate.bind(this, {
                    isNewTarget: true
                }),
                id: 'new_template',
                permissions: ['programs.templates.actions.new']
            })

        } catch(e) {
            console.log(e.messsage);
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props)
        setProgram(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: isNewTarget ? 'Done' : 'Save Changes'
        }];
    }

    const getFields = () => {

        if(!program) {
            return [];
        }

        let items = [{
            key: 'about',
            title: `About this ${Utils.ucFirst(getLabel())}`,
            items: [{
                component: 'textfield',
                description: `What is the name of this ${getLabel()}?`,
                key: 'name',
                onChange: text => onUpdateTarget({ name: text }),
                title: 'Name',
                value: program.name
            },{
                component: 'textview',
                description: `What is the description for this ${getLabel()}?`,
                key: 'description',
                onChange: text => onUpdateTarget({ description: text }),
                title: 'Description',
                value: program.description
            },/*{
                key: 'agreement_template',
                required: false,
                visible: isNewTarget === false && abstract.getID() === 1,
                title: 'Rules and Disclaimers',
                description: `Rules and Disclaimers are shown to Safety Associates when they are enrolled in this ${getLabel()}. Safety Associates will be required to agree to these Rules and Disclaimers each time they login to the Global Data platform.`,
                value: agreementTemplate ? agreementTemplate.title : null,
                component: 'program_agreement_template_picker',
                onChange: agreement => setAgreementTemplate(agreement)
            },*/{
                component: 'privacy_editor',
                description: `Terms and Conditions is a free form area that allows you to show customer facing content that must be agreed to before participating in this ${getLabel()}`,
                key: 'privacy',
                onChange: content => onUpdateTarget({ privacy: content }),
                required: false,
                title: 'Terms and Conditions',
                value: program.privacy,
                visible: program.dealership || utils.user.get().level === User.levels.get().admin
            }]
        },{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'list',
                description: 'What is the purpose of this survey? The category determines which fields are shown to the customer when filling out the survey.',
                items: [{
                    id: 'lead_generation',
                    title: 'Lead Generation'
                },{
                    id: 'recruiting',
                    title: 'Recruiting'
                }],
                key: 'category',
                props: {
                    placeholder: 'Choose a category...'
                },
                onChange: item => onUpdateTarget({ category: item ? item.id : null }),
                title: 'Category',
                value: program.category ? (program.category === 'recruiting' ? 'Recruiting' : 'Lead Generation') : null,
                visible: program.allow_sharing === true
            },{
                component: 'dealership_lookup',
                description: `What dealership does this ${getLabel()} belong to?`,
                key: 'dealership',
                onChange: dealership => onUpdateTarget({ dealership: dealership }),
                required: false,
                title: 'Dealership',
                value: program.dealership,
                visible: isNewTarget && utils.user.get().level <= User.levels.get().admin
            },{
                component: 'list',
                description: 'Would you like this program to have an ending date? If yes, choose the type of end date that you would like to create.',
                items: [{
                    id: 'date',
                    title: 'Specific Date'
                },{
                    id: 'days',
                    title: 'Number of Days After Signup'
                }],
                key: 'end_date_type',
                onChange: item => onUpdateTarget({ end_date_type: item ? item.id : null }),
                props: {
                    placeholder: 'Choose an end date type...'
                },
                required: false,
                title: 'End Date',
                value: program.end_date_type ? (program.end_date_type === 'date' ? 'Specific Date' : 'Number of Days After Signup') : null,
                visible: program.allow_sharing === false
            },{
                component: 'date_picker',
                description: 'Would you like this program to end on a specific date?',
                key: 'end_date',
                onChange: date => onUpdateTarget({ end_date: date }),
                props: {
                    dateTime: false
                },
                required: false,
                title: 'End on Specific Date',
                value: program.end_date,
                visible: program.end_date_type === 'date'
            },{
                component: 'list',
                description: `Would you like this program to end based on the safety associate's signup date? If yes, then choose the amount of days that the safety associate has to complete this program`,
                items: [...new Array(365)].map((_, index) => ({
                    code: index + 1,
                    title: `${index + 1} ${(index + 1) === 1 ? 'day':'days'}`
                })),
                key: 'end_days',
                onChange: item => onUpdateTarget({ end_days: item ? item.code : null }),
                props: {
                    placeholder: 'Choose an amount of days...',
                },
                required: false,
                title: 'End a Number of Days After Signup',
                value: program.end_days ? `${program.end_days} ${program.end_days === 1 ? 'day':'days'}` : null,
                visible: program.end_date_type === 'days' 
            },{
                component: 'textfield',
                description: `If needed, you can set a goal for "Total Demos Set" that all enrolled users must meet in order to fulfil the requirements of this program. Setting a goal for demos is optional.`,
                key: 'demo_goals',
                onChange: text => {
                    if(!text) {
                        onUpdateTarget({ props: null });
                        return;
                    }
                    if(!program.props || !program.props.goals) {
                        onUpdateTarget({
                            props: {
                                goals: {
                                    demos: parseInt(text)
                                }
                            }
                        });
                        return;
                    }
                    onUpdateTarget({
                        props: update(program.props, {
                            goals: {
                                demos: {
                                    $set: parseInt(text)
                                }
                            }
                        })
                    })
                },
                props: {
                    format: 'integer',
                },
                required: false,
                title: 'Demos Goal',
                value: program.props && program.props.goals ? program.props.goals.demos : null,
                visible: program.allow_sharing === false
            },{
                component: 'list',
                description: `What template would you like to use for this ${getLabel()}?`,
                items: getTemplateItems(),
                key: 'template',
                onChange: item => {
                    if(!item) {
                        onUpdateTarget({ template: null });
                        return;
                    }
                    let template = templates.find(temp => temp.id === item.code);
                    onUpdateTarget({ template: template });
                },
                props: {
                    onAddNew: onNewTemplate
                },
                required: program.allow_sharing,
                title: 'Template',
                value: program.template ? program.template.title : null,
                visible: program.allow_sharing
            },{
                component: 'lead_type_picker',
                description: `Would you like automatically associate a specific lead type with this survey? This will tag all leads generated through this survey with the selected lead type`,
                key: 'lead_type',
                onChange: result => onUpdateTarget({ lead_type: result }),
                required: false,
                title: 'Lead Type',
                value: program.lead_type ? program.lead_type.text : null,
                visible: program.allow_sharing && program.category === 'lead_generation'
            },{
                component: 'list',
                description: `Would you like to choose a lead script for this ${getLabel()}? All leads generated with this ${getLabel()} will automatically have this lead script attached. We will attach your dealership's default lead script if you do not choose a lead script.`,
                items: scripts,
                key: 'lead_script',
                onChange: lead_script => onUpdateTarget({ lead_script: lead_script }),
                required: false,
                title: 'Lead Script',
                value: program.lead_script ? program.lead_script.text : null,
                visible: program.allow_sharing && program.category === 'lead_generation' && scripts.length > 0
            }]
        }]

        return items;
    }

    const getLabel = () => {
        return program && program.allow_sharing === true ? 'survey' : 'program';
    }

    const getTemplateItems = () => {
        return templates.filter(template => {
            return template.category === program.category
        }).map(template => ({
            code: template.id,
            title: template.title
        }));
    }

    const getTitle = () => {
        if(!isNewTarget) {
            return `Editing ${abstract.getTitle()}`;
        }
        return `New ${Utils.ucFirst(getLabel())}`;
    }

    const fetchEditTargets = async () => {
        try {
            let { scripts, templates } = await Request.get(utils, '/programs/', {
                type: 'edit_targets'
            });
            setScripts(scripts.map(script => Lead.Script.create(script)))
            setTemplates(templates.map(template => Program.Template.create(template)));

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing setup. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        if(isNewTarget) {
            edits = abstract.object.set({ dealership: utils.dealership.get() });
        }
        setProgram(edits);
        fetchEditTargets();
    }

    useEffect(() => {
        setupTarget();
        utils.content.subscribe(layerID, ['program', 'template'], {
            onFetch: type => {
                switch(type) {
                    case 'template':
                    fetchEditTargets();
                    break;
                }
            }
        })
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={getTitle()}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const AddEditProgramAgreementTemplate = ({ isNewTarget, onAddTemplate }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_program_agreement_template` : `edit_program_agreement_template_${abstract.getID()}`;
    const [items, setItems] = useState([]);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState(null);

    const onDoneClick = async () => {

        try {
            setLoading('done');
            await Utils.sleep(0.25);
            await validateRequiredFields(getFields);

            // create target
            if(isNewTarget) {
                await abstract.object.submit(utils);
                setLoading(false);
                utils.alert.show({
                    title: 'All Done!',
                    message: `Your custom agreement has been created and will be shown whenever an agreement is requested for this program.`,
                    onClick: () => {
                        setLayerState('close');
                        if(typeof(onAddTemplate) === 'function') {
                            onAddTemplate(abstract.object);
                        }
                    }
                });
                return;
            }

            // update target
            await abstract.object.update(utils);
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your custom agreement has been updated and will be available the next time an agreement is requested for this program.`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this template. ${e.message || 'An unknown error occurred'}`
            })
        }
        return;
    }

    const onFormatProp = entry => {
        let value = template.props[entry.code];
        return {
            key: entry.code,
            required: true,
            title: entry.text,
            description: entry.description,
            value: value,
            component: 'textfield',
            onChange: val => {
                onUpdateTarget({
                    props: update(template.props, {
                        [entry.code]: {
                            $set: val
                        }
                    })
                });
            }
        }
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props);
        setTemplate(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: isNewTarget ? 'Done' : 'Save Changes'
        }];
    }

    const getFields = () => {

        if(!template) {
            return [];
        }

        return [{
            key: 'compensation',
            title: 'Compensation',
            items: items.filter(entry => {
                return [ 'super_commission' ].includes(entry.code);
            }).map(onFormatProp)
        },{
            key: 'timeframe',
            title: 'Timeframe',
            items: items.filter(entry => {
                return [ 'max_days', 'payment_days' ].includes(entry.code);
            }).map(onFormatProp)
        },{
            key: 'first',
            title: 'First Super Commission Qualifications',
            items: items.filter(entry => {
                return [ 'super_qualified_demos', 'super_qualified_sales' ].includes(entry.code);
            }).map(onFormatProp)
        },{
            key: 'bonus',
            title: 'Bonus Qualifications',
            items: items.filter(entry => {
                return [ 'bonus_alt_prize_options', 'bonus_prize_options', 'bonus_qualified_demos', 'bonus_scheduled_demos', 'bonus_scheduled_demos_days' ].includes(entry.code);
            }).map(onFormatProp)
        }];
    }

    const setupTarget = async () => {
        try {

            // fetch options
            let { options } = await Request.get(utils, '/programs/', {
                type: 'agreement_template_options'
            });
            setItems(options);

            // open editing
            let edits = abstract.object.open();
            if(isNewTarget) {
                edits = abstract.object.set({
                    dealership: utils.dealership.get(),
                    props: options.reduce((object, option) => {
                        object[option.code] = option.default_value;
                        return object;
                    }, {})
                });
            }
            setTemplate(edits);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue completing setup. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? 'New Program Agreement Template' : `Editing "${abstract.getTitle()}"`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading === true,
            sizing: 'medium'
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>
        </Layer>
    )
}

export const AddEditTemplate = ({ isNewTarget }, { abstract, index, options, utils }) => {

    const layerID = isNewTarget ? `new_template` : `edit_template_${abstract.getID()}`;
    const [category, setCategory] = useState(abstract.object.category);
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sorting, setSorting] = useState(null);
    const [template, setTemplate] = useState(null);

    const onDeleteQuestion = (question, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Delete Question',
            message: 'Are you sure that you want to delete this question? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onUpdateTarget({
                        questions: update(abstract.object.edits.questions, {
                            $apply: questions => questions.filter(prev_question => {
                                return prev_question.id !== question.id;
                            })
                        })
                    });
                    return;
                }
            }
        });
    }

    const onDoneClick = async () => {
        try {

            // verify that required fields have been completed
            setLoading('done');
            await validateRequiredFields(getFields);

            // determine if a new target needs to be created
            if(isNewTarget) {
                await abstract.object.submit(utils);
            } else {
                await abstract.object.update(utils);
            }

            // show confirmation alert
            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `Your new template has been ${isNewTarget ? 'created' : 'updated'}`,
                onClick: () => {
                    if(isNewTarget) {
                        setLayerState('close');
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue ${isNewTarget ? 'creating' : 'updating'} this template. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditQuestion = question => {

        // prevent editing for questions that are marked as not-editable
        if(question && question.editable === false) {
            utils.alert.show({
                title: 'Just a Second',
                message: `This question is used to generate one or more reports and can not be modified. ${question.sortable === true ? 'However, you can move the question to a new position in your template if needed' : ''}`
            });
            return;
        }

        // open editing layer for question
        utils.layer.open({
            id: question ? `edit_template_question_${question.id}` : 'new_template_question',
            Component: AddEditTemplateQuestion.bind(this, {
                isNewTarget: question ? false : true,
                onChange: result => {
                    result.editable = true;
                    result.sortable = true;
                    result.order_index = template.questions.length + 1;
                    onUpdateQuestion(result);
                },
                question: question
            })
        });
    }

    const onMoveQuestionClick = (id, value, evt) => {

        // prevent parent click
        evt.stopPropagation();

        // prevent an invalid position from being requested
        let index = template.questions.findIndex(question => question.id === id);
        let val = index + value;
        if(val < 0 || val > template.questions.length - 1) {
            return;
        }

        // update questions order
        let question = template.questions[index];
        let questions = update(template.questions, {
            $splice: [[
                index, 1
            ],[
                val, 0, question
            ]]
        }).map((question, index) => {
            question.order_index = index;
            return question;
        });

        // update local state with order changes
        let edits = abstract.object.set({ questions });
        setTemplate(edits);
    }

    const onSortChange = async ({  newIndex, oldIndex }) => {
        setSorting(null);
        let question = template.questions[oldIndex];
        let edits = abstract.object.set({
            questions: update(template.questions, {
                $splice: [[ oldIndex, 1 ], [ newIndex, 0, question ]]
            }).map((question, index) => {
                question.order_index = index + 1;
                return question;
            })
        });
        setTemplate({ ...edits });
    }

    const onSortStart = ({ index }) => {
        setSorting(index);
    }

    const onUpdateQuestion = question => {
        onUpdateTarget({
            questions: update(abstract.object.edits.questions, {
                $apply: questions => questions.filter(prev_question => {
                    return prev_question.id !== question.id;
                }),
                $push: [ question ]
            })
        })
    }

    const onUpdateTarget = props => {
        let edits = abstract.object.set(props)
        setTemplate(edits);
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            loading: loading === 'done',
            onClick: onDoneClick,
            text: isNewTarget ? 'Done' : 'Save Changes'
        }];
    }

    const getCustomizations = () => {

        if(!template) {
            return [];
        }
        return [{
            key: 'customize',
            title: 'Customize',
            items: [{
                key: 'welcome_message',
                nesting: 'props',
                required: false,
                title: 'Welcome Message',
                description: 'Would you like your welcome message to say? This message is shown at the top of your template.',
                value: template.props.welcome_message,
                component: 'textview',
                onChange: text => {
                    onUpdateTarget({
                        props: update(template.props, {
                            welcome_message: {
                                $set: text
                            }
                        })
                    });
                }
            },{
                key: 'header_image',
                nesting: 'props',
                required: false,
                title: 'Hero Image',
                description: 'Would you like to add an image at the top of your template? Images must be at least 1024 pixels wide by 512 pixels high. We will show your dealership name, city, and state if no image is added.',
                value: template.props.header_image,
                component: 'image_picker',
                props: {
                    requirements: {
                        dimensions: {
                            width: 1024,
                            height: 512
                        }
                    }
                },
                onChange: image => {
                    onUpdateTarget({
                        props: update(template.props, {
                            header_image: {
                                $set: image
                            }
                        })
                    })
                }
            },{
                key: 'background_image',
                required: false,
                title: 'Background Image',
                description: 'Would you like to add an image to the background of this template? Images are shown fullscreen and must be at least 1920 pixels high by 3072 pixels wide. This ensures that your Program does not have a blurry background when viewed on larger displays.',
                value: template.props.background_image,
                component: 'image_picker',
                props: {
                    requirements: {
                        dimensions: {
                            width: 3072,
                            height: 1920
                        }
                    }
                },
                onChange: image => {
                    onUpdateTarget({
                        props: update(template.props, {
                            background_image: {
                                $set: image
                            }
                        })
                    })
                }
            },{
                key: 'og_image',
                required: false,
                title: 'Social Media Image',
                description: 'Would you like to show a custom image when this Program is shared on social media? Images must be at least 627 pixels high by 1200 pixels wide. We will show the Global Data logo if no image is selected.',
                value: template.props.og_image,
                component: 'image_picker',
                props: {
                    requirements: {
                        dimensions: {
                            width: 1200,
                            height: 627
                        }
                    }
                },
                onChange: image => {
                    onUpdateTarget({
                        props: update(template.props, {
                            og_image: {
                                $set: image
                            }
                        })
                    })
                }
            },{
                key: 'pattern',
                nesting: 'props',
                required: false,
                title: 'Background Pattern',
                description: 'Would you like to add a pattern to the background of this template? This pattern will appear over your background image if you have added a background image.',
                value: template.props ? template.props.pattern : null,
                component: 'pattern_picker',
                onChange: pattern => {
                    onUpdateTarget({
                        props: update(template.props, {
                            pattern: {
                                $set: pattern
                            }
                        })
                    })
                }
            }]
        }]
    }

    const getFields = () => {
        if(!template) {
            return [];
        }
        return [{
            key: 'details',
            title: 'Details',
            items: [{
                component: 'list',
                description: 'What is the purpose of this template? The category determines which fields are shown to the customer when filling out a survey where this template is set.',
                items: [{
                    id: 'lead_generation',
                    title: 'Lead Generation'
                },{
                    id: 'recruiting',
                    title: 'Recruiting'
                }],
                key: 'category',
                props: {
                    placeholder: 'Choose a category...'
                },
                onChange: item => onUpdateTarget({ category: item ? item.id : null }),
                title: 'Category',
                value: template.category ? (template.category === 'recruiting' ? 'Recruiting' : 'Lead Generation') : null
            },{
                component: 'dealership_lookup',
                description: 'Which dealership do you want to have access to this template? Leaving this blank will allow all dealerships to use this template.',
                key: 'dealership',
                onChange: dealership => onUpdateTarget({ dealership: dealership }),
                required: false,
                title: 'Dealership',
                value: template.dealership,
                visible: isNewTarget === true && utils.user.get().level <= User.levels.get().admin
            },{
                component: 'textfield',
                description: 'What is the title of this template? This title will appear when a customer visits a program using this template',
                key: 'title',
                onChange: text => onUpdateTarget({ title: text }),
                required: true,
                title: 'Title',
                value: template.title
            },{
                component: 'textview',
                description: 'What is the description for this template? This description will not be shown to a customer when they visit a program using this template',
                key: 'description',
                onChange: text => onUpdateTarget({ description: text }),
                required: true,
                title: 'Description',
                value: template.description
            },{
                component: 'bool_list',
                description: 'Should we give the customer an option to request a demo?',
                key: 'request_demo',
                onChange: enabled => onUpdateTarget({ request_demo: enabled }),
                required: false,
                title: 'Request a Demo',
                value: template.request_demo,
                visible: template.category === 'lead_generation'
            }]
        }];
    }

    const getQuestions = () => {
        if(!template || !template.questions) {
            return [];
        }
        return template.questions.sort((a, b) => {
            return a.order_index - b.order_index;
        });
    }

    const getQuestionComponent = (question, index, questions) => {
        let { editable, sortable, title, type } = question;
        let locked = editable === false && sortable === false;
        return (
            <div
            {...type !== Program.Template.Question.type.file_picker && {
                className: `view-entry ${window.theme}`,
                onClick: onEditQuestion.bind(this, question)
            }}
            key={index}
            style={{
                alignItems: 'center',
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                display: 'flex',
                flexDirection: 'row',
                padding: '8px 12px 8px 12px',
                width: '100%',
            }}>
                {locked && (
                    <img
                    src={'images/lock-icon-small.png'}
                    style={{
                        height: 20,
                        marginRight: 4,
                        objectFit: 'contain',
                        width: 20
                    }} />
                )}
                {sortable && (
                    <img
                    src={`images/sort-icon-${sorting === index ? 'blue' : 'grey'}-small.png`}
                    style={{
                        height: 20,
                        marginRight: 4,
                        objectFit: 'contain',
                        width: 20
                    }} />
                )}
                <div style={{
                    flexGrow: 1,
                    minWidth: 0
                }}>
                    {Views.entry({
                        badge: [{
                            text: question.required ? 'Required' : null,
                            color: Appearance.colors.red
                        }],
                        bottomBorder: false,
                        hideIcon: true,
                        subTitle: `${Program.Template.Question.input.getLabel(question)}: ${Program.Template.Question.input.getDescription(question)}`,
                        title: title
                    })}
                </div>
                <img
                className={'text-button'}
                src={'images/up-arrow-grey-small.png'}
                title={'Move Up'}
                onClick={onMoveQuestionClick.bind(this, question.id, -1)}
                style={{
                    height: 20,
                    marginLeft: 8,
                    minWidth: 20,
                    objectFit: 'contain',
                    opacity: index === 0 ? 0.5 : 1,
                    overflow: 'hidden',
                    width: 20
                }} />
                <img
                className={'text-button'}
                src={'images/down-arrow-grey-small.png'}
                title={'Move Down'}
                onClick={onMoveQuestionClick.bind(this, question.id, 1)}
                style={{
                    height: 20,
                    marginLeft: 2,
                    minWidth: 20,
                    objectFit: 'contain',
                    opacity: index === questions.length - 1 ? 0.5 : 1,
                    overflow: 'hidden',
                    width: 20
                }} />
                <img
                className={'text-button'}
                src={'images/red-x-icon.png'}
                onClick={onDeleteQuestion.bind(this, question)}
                style={{
                    height: 20,
                    marginLeft: 8,
                    objectFit: 'contain',
                    width: 20
                }} />
            </div>
        )
    }

    const getQuestionsContainer = () => {
        return (
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 20
            }}>
                <QuestionsContainer
                axis={'y'}
                lockAxis={'y'}
                pressDelay={200}
                onSortStart={onSortStart}
                onSortEnd={onSortChange}
                helperClass={'sortable-container'}
                questions={getQuestions()}
                onRenderQuestion={getQuestionComponent}/>

                <div
                className={'text-button'}
                onClick={onEditQuestion.bind(this, null)}
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: 12,
                    textAlign: 'center',
                    width: '100%'
                }}>
                    <span style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.primary()
                    }}>{'Add a New Question'}</span>
                </div>
            </div>
        )
    }

    const fetchDefaultQuestions = async () => {
        try {

            // send request to server
            setLoading(true);
            let { questions } = await Request.get(utils, '/programs/', {
                category: category,
                type: 'default_template_questions'
            });

            // update template edits with default questions
            let edits = abstract.object.set({
                dealership: utils.dealership.get(),
                questions: questions.map(question => {
                    return Program.Template.Question.create(question);
                })
            });

            // update local state with default questions
            setLoading(false);
            setTemplate(edits);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the default questions for this template category. ${e.message || 'An unknown error occurred'}`,
                onClick: setLayerState.bind(this, 'close')
            });
        }
    }

    const setupTarget = () => {
        let edits = abstract.object.open();
        setTemplate(edits); 
    }

    useEffect(() => {
        if(category && isNewTarget === true) {
            fetchDefaultQuestions();
        }
    }, [category]);

    useEffect(() => {
        setCategory(template && template.category);
    }, [template]);

    useEffect(() => {
        setupTarget();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={isNewTarget ? `New Template` : `Editing ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading == true
        }}>
            <AltFieldMapper
            fields={getFields()} 
            utils={utils}/>

            <LayerItem
            title={'Questions'}
            style={{
                marginBottom: 35
            }}>
                {getQuestionsContainer()}
            </LayerItem>

            <AltFieldMapper
            fields={getCustomizations()} 
            utils={utils}/>
        </Layer>
    )
}

const AddEditTemplateQuestion = ({ isNewTarget, onChange, question }, { index, options, utils }) => {

    const layerID = isNewTarget ? 'new_template_question' : `edit_template_question_${question.id}`;
    const [layerState, setLayerState] = useState(null);
    const [result, setResult] = useState(question);

    const onDoneClick = () => {

        // close layer and notify subscribers of data change
        setLayerState('close');
        if(typeof(onChange) === 'function') {
            onChange(result);
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onDoneClick,
            text: 'Done'
        }];
    }

    return (
        <Layer 
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={question ? `Editing "${question.title}"` : 'New Question'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            sizing: 'medium'
        }}>
            <QuestionEditor
            onAdd={setResult} 
            onClose={setLayerState.bind(this, 'close')}
            onUpdate={setResult}
            utils={utils}
            value={question} />
        </Layer>
    )
}

export const EnrollProgramLead = ({ abstract, index, options, utils }) => {

    const layerID = `enroll_program_lead_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [lead, setLead] = useState(null);

    const onEnrollProgram = async () => {
        if(!lead) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please choose a Lead before moving on`
            });
            return;
        }

        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/programs/', {
                type: 'enroll_program_lead',
                id: lead.id,
                program_id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('program');

            utils.alert.show({
                title: 'All Done!',
                message: `${lead.full_name} has been enrolled in the Program "${abstract.object.name}"`,
                onClick: () => setLayerState('close')
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue enrolling ${lead.full_name} in this Program. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onEnrollProgram,
            text: 'Enroll'
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Program Enrollment'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <LeadLookupField
            icon={'search'}
            onChange={setLead}
            placeholder={'Search for a Lead...'}
            utils={utils}/>
        </Layer>
    )
}

export const EnrollProgramUser = ({ abstract, index, options, utils }) => {

    const layerID = `enroll_program_user_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [layerState, setLayerState] = useState(null);
    const [user, setUser] = useState(null);

    const onEnrollProgram = async () => {
        if(!user) {
            utils.alert.show({
                title: 'Just a Second',
                message: `Please choose a Safety Associate before moving on`
            });
            return;
        }

        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/programs/', {
                type: 'enroll_program_user',
                user_id: user.user_id,
                program_id: abstract.getID()
            });

            setLoading(false);
            utils.content.fetch('program');

            utils.alert.show({
                title: 'All Done!',
                message: `${user.full_name} has been enrolled in the Program "${abstract.object.name}"`,
                onClick: () => setLayerState('close')
            })

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue enrolling ${user.full_name} in this Program. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        return [{
            color: 'primary',
            key: 'done',
            onClick: onEnrollProgram,
            text: 'Enroll'
        }];
    }

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Program Enrollment'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <UserLookupField
            utils={utils}
            icon={'search'}
            placeholder={'Search for a Safety Associate...'}
            levels={[ User.levels.get().safety_associate ]}
            onChange={user => setUser(user)}/>
        </Layer>
    )
}

export const ProgramPrivacyEditor = ({ content, preview, onChange }, { abstract, index, options, utils }) => {

    const layerID = abstract ? `program_privacy_editor_${abstract.getID()}` : 'new_program_privacy_editor';
    const [contentState, setContentState] = useState(null);
    const [previewContent, setPreviewContent] = useState(null);
    const [layerState, setLayerState] = useState(null);

    const onContentChange = state => {
        setContentState(state);
    }

    const onDoneClick = () => {
        if(!contentState) {
            setLayerState('close');
            return;
        }
        try {
            let result = convertToRaw(contentState.getCurrentContent());
            setLayerState('close');
            if(typeof(onChange) === 'function') {
                onChange(result);
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const getButtons = () => {
        if(preview) {
            return null;
        }
        return [{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onDoneClick
        }]
    }

    const getContent = () => {
        if(preview === true) {
            return (
                <div dangerouslySetInnerHTML={{ __html: previewContent }} />
            )
        }
        return (
            <RichTextEditor
            utils={utils}
            content={content}
            onChange={onContentChange}/>
        )
    }

    const setupPreviewContent = () => {
        if(preview !== true || !content) {
            return;
        }
        try {
            let markup = draftToHtml(content);
            setPreviewContent(markup);
        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your content. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    useEffect(() => {
        setupPreviewContent();
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Program Terms and Conditions Editor'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState
        }}>
            {getContent()}
        </Layer>
    )
}

export const ProgramAgreementTemplateDetails = ({ abstract, index, options, utils }) => {

    const layerID = `program_agreement_template_details_${abstract.getID()}`;
    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [url, setURL] = useState(null);

    const onEditClick = () => {
        if(!abstract.object.dealership && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This Template is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new Template if you need to change the information in this Template.'
            });
            return;
        }
        utils.layer.open({
            id: `edit_program_agreement_template_${abstract.getID()}`,
            abstract: abstract,
            Component: AddEditProgramAgreementTemplate.bind(this, {
                isNewTarget: false
            })
        })
    }

    const onFormatProp = entry => {
        let value = abstract.object.props[entry.code];
        if(value !== null && value !== undefined) {
            if(entry.code.includes('commission')) {
                value = Utils.toCurrency(value, 'USD');

            } else if(entry.code.includes('days')) {
                value = `${Utils.softNumberFormat(value)} ${parseInt(value) === 1 ? 'Day' : 'Days'}`

            } else if(!isNaN(value)) {
                value = Utils.softNumberFormat(value);
            }
        }
        return {
            key: entry.code,
            title: entry.text,
            value: value
        }
    }

    const getButtons = () => {
        return [{
            key: 'edit',
            text: 'Edit',
            color: 'primary',
            onClick: onEditClick
        }];
    }

    const getFields = () => {

        let template = abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'id',
                title: 'ID',
                value: template.id
            },{
                key: 'title',
                title: 'Title',
                value: template.title
            },{
                key: 'date',
                title: 'Created',
                value: template.date ? Utils.formatDate(template.date) : null
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                value: template.dealership ? template.dealership.name : null
            }]
        },{
            key: 'compensation',
            title: 'Compensation',
            items: template.options.filter(entry => {
                return [ 'super_commission', 'ultra_commission' ].includes(entry.code);
            }).map(onFormatProp)
        },{
            key: 'timeframe',
            title: 'Timeframe',
            items: template.options.filter(entry => {
                return [ 'max_days', 'payment_days' ].includes(entry.code);
            }).map(onFormatProp)
        },{
            key: 'first',
            title: 'First Super Commission Qualifications',
            items: template.options.filter(entry => {
                return [ 'first_super_qualified_demos', 'first_super_qualified_sales' ].includes(entry.code);
            }).map(onFormatProp)
        },{
            key: 'second',
            title: 'Second Super Commission Qualifications',
            items: template.options.filter(entry => {
                return [ 'second_super_qualified_demos', 'second_super_qualified_sales' ].includes(entry.code);
            }).map(onFormatProp)
        },{
            key: 'ultra',
            title: 'Ultra Commission Qualifications',
            items: template.options.filter(entry => {
                return [ 'ultra_qualified_demos', 'ultra_qualified_sales' ].includes(entry.code);
            }).map(onFormatProp)
        },{
            key: 'bonus',
            title: 'Bonus Qualifications',
            items: template.options.filter(entry => {
                return [ 'bonus_alt_prize_options', 'bonus_prize_options', 'bonus_qualified_demos', 'bonus_scheduled_demos', 'bonus_scheduled_demos_days' ].includes(entry.code);
            }).map(onFormatProp)
        }];

        return items;
    }

    useEffect(() => {
        setURL(`${API.server}/privacy/agreements/index.html?id=${abstract.getID()}&dealership_id=${utils.dealership.get().id}&nonce=${moment().unix()}&preview=1`);
        utils.content.subscribe(layerID, ['program_agreement_template'], {
            onUpdate: _abstract => {
                if(_abstract.getTag() === abstract.getTag()) {
                    setURL(`${API.server}/privacy/agreements/index.html?id=${abstract.getID()}&dealership_id=${utils.dealership.get().id}&nonce=${moment().unix()}&preview=1`);
                }
            }
        })
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for "${abstract.getTitle()}"`}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 20
            }}>
                <div style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    height: 350
                }}>
                    <iframe
                    className={'template-preview'}
                    src={url}
                    style={{
                        border: 'none',
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        height: 350,
                        width: '100%'
                    }} />
                </div>
                <div style={{
                    padding: 12,
                    textAlign: 'center'
                }}>
                    <span
                    className={'text-button'}
                    onClick={() => window.open(url)}
                    style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.primary()
                    }}>{'Open Preview in New Window'}</span>
                </div>
            </div>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}

export const ProgramDetails = ({ abstract, index, options, utils }) => {

    const layerID = `program_details_${abstract.getID()}`;
    const urlLimit = useRef(5);

    const [interactionsCount, setInteractionsCount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [program, setProgram] = useState(abstract.object);
    const [urls, setUrls] = useState([]);
    const [urlOffset, setUrlOffset] = useState(0);
    const [urlPaging, setUrlPaging] = useState(null);

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `${abstract.object.active ? 'Deactivate' : 'Activate'} ${Utils.ucFirst(getLabel())}`,
            message: `Are you sure that you want to set "${abstract.object.title}" as ${abstract.object.active ? 'inactive' : 'active'}?`,
            buttons: [{
                key: 'confirm',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            await Request.post(utils, '/programs/', {
                type: 'set_active_status',
                id: abstract.getID(),
                active: !abstract.object.active
            });

            abstract.object.active = !abstract.object.active;
            setProgram(abstract.object);
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This Program has been set as ${abstract.object.active ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this Program. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onChangeUrlActiveStatus = url => {
        utils.alert.show({
            title: `${url.active ? 'Deactivate' : 'Activate'} URL`,
            message: `Are you sure that you want to ${url.active ? 'deactivate' : 'activate'} this url?`,
            buttons: [{
                key: 'confirm',
                title: `${url.active ? 'Deactivate' : 'Activate'}`,
                style: url.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: url.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeUrlActiveStatusConfirm(url);
                    return;
                }
            }
        });
    }

    const onChangeUrlActiveStatusConfirm = async url => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let status = !url.active;
            await Request.post(utils, '/programs/', {
                type: 'set_url_active_status',
                id: url.id,
                active: status
            });

            setUrls(urls => {
                return urls.map(prev_url => {
                    if(prev_url.id === url.id) {
                        prev_url.active = status;
                    }
                    return prev_url;
                })
            });

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This url has been ${status ? 'activated' : 'deactivated'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue changing the status of this url. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onCopyProgramURL = async url => {

        let textArea = document.createElement('textarea');
        textArea.value = url;
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.position = 'fixed';
        textArea.style.opacity = 0;
        document.body.appendChild(textArea);

        textArea.focus();
        textArea.select();

        try {
            let copied = document.execCommand('copy');
            document.body.removeChild(textArea);
            if(!copied) {
                throw new Error('Your browser denied the copy request');
            }

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue copying your Program link. ${(typeof(e) === 'string' ? e : e.message) || 'An unknown error occurred'}`
            })
        }
    }

    const onCustomizeCareAndShareAgreement = async () => {
        try {
            setLoading(true);
            let { template } = await Request.get(utils, '/programs/', {
                type: 'care_and_share_agreement',
                dealership_id: utils.dealership.get().id,
            });

            // edit agreement if an agreement was previously created
            setLoading(false);
            if(template) {
                let target = Program.Agreement.Template.create(template);
                utils.layer.open({
                    id: `edit_program_agreement_template_${target.id}`,
                    abstract: Abstract.create({
                        type: 'program_agreement_template',
                        object: target
                    }),
                    Component: AddEditProgramAgreementTemplate.bind(this, {
                        isNewTarget: false
                    })
                });
                return;
            }

            // create new agreement if no agreement was previously created
            let target = Program.Agreement.Template.new();
            target.title = 'Care and Share Agreement';
            utils.layer.open({
                id: 'new_program_agreement_template',
                abstract: Abstract.create({
                    type: 'program_agreement_template',
                    object: target
                }),
                Component: AddEditProgramAgreementTemplate.bind(this, {
                    isNewTarget: true
                })
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue preparing your custom agreement. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onDownloadProgramQRCode = async url => {
        try {
            let link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.download = 'qr_code.png';
            document.body.appendChild(link);
            link.click();

            await Utils.sleep(0.25);
            document.body.removeChild(link);

        } catch(e) {
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue downloading your Program QR Code. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onEditClick = () => {

        // prevent moving forward if program is global and user is not an administrator
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This Program is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new program if you need to change the information in this Program.'
            });
            return;
        }

        // open layer to edit program
        utils.layer.open({
            abstract: abstract,
            Component: AddEditProgram.bind(this, {
                isNewTarget: false
            }),
            id: `edit_program_${abstract.getID()}`,
            permissions: ['programs.actions.edit']
        });
    }

    const onGenerateURL = () => {
        utils.sheet.show({
            title: 'Generate a Public URL',
            message: `What type of url are you needing? A general purpose url can be used across your entire dealership. Survey kiosk mode automatically refreshes the survey after a submission. A survey credit url is meant to give specific lead credit each time a new lead is generated using the url.`,
            items: [{
                key: 'general',
                title: 'General Purpose',
                style: 'default'
            },{
                key: 'kiosk',
                title: 'Survey Kiosk Mode',
                style: 'default'
            },{
                key: 'enrollment_credit',
                title: 'URL with Survey Credit',
                style: 'default'
            }]
        }, key => {
            if(key === 'general') {
                onGenerateURLProps();
                return;
            }
            if(key === 'kiosk'){
                onGenerateURLConfirm({ kiosk: true });
                return;
            }
            if(key === 'enrollment_credit'){
                onGenerateURLProps({ enroll_user: true });
                return;
            }
        });
    }

    const onGenerateURLProps = props => {

        let { enroll_user } = props || {};

        // prepare variables for domain name selection, enrollment user, and custom url label
        let domain = 'gdl-link.com';
        let user = null;
        let value = null;

        // show alert requesting custom url values
        utils.alert.show({
            title: enroll_user ? 'URL with Survey Credit' : 'Public URL',
            message: `${enroll_user ? 'Generating a url with survey credit allows us to give the proper credit when a Lead signs up using the url. ' : ''}All urls are automatically shortened for quicker sharing but can be easily customized with your own label. A gdl-link.com url with a custom label would look like https://gdl-link.com/your-label-here. We'll generate a label for you if you don't feel like creating your own.`,
            content: (
                <div style={{
                    padding: 12,
                    width: '100%'
                }}>
                    <ListField 
                    disablePlaceholder={true}
                    items={getDomainNameItems()}
                    onChange={item => domain = item.id} 
                    placeholder={'Domain Name'}
                    style={{
                        marginBottom: 8
                    }}
                    value={domain}/>
                    {enroll_user && (
                        <UserLookupField
                        containerStyle={{
                            marginBottom: 8
                        }}
                        icon={'none'}
                        onChange={result => user = result}
                        placeholder={'Assign Credit To...'} 
                        utils={utils}/>
                    )}
                    <TextField
                    onChange={text => value = text}
                    placeholder={'Custom Label (optional)'}/>
                </div>
            ),
            buttons: [{
                key: 'done',
                title: 'Create URL',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: async key => {
                if(key === 'done') {
                    if(enroll_user && !user) {
                        return;
                    }
                    onGenerateURLConfirm({
                        ...props,
                        domain: domain,
                        short_name: value,
                        user: user
                    });
                }
            }
        });
    }

    const onGenerateURLConfirm = async ({ domain, kiosk, short_name, user }) => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            let { qr_code, url } = await Request.get(utils, '/programs/', {
                domain: domain,
                enrollment_user_id: user && user.user_id,
                kiosk: kiosk,
                program_id: abstract.getID(),
                short_name: short_name,
                type: 'public_url'
            });

            setLoading(false);
            fetchURLs();

            utils.alert.show({
                title: 'All Done!',
                message: `We have created ${user ? `the survey credit url for ${user.full_name}` : 'general-use url that can be used across your dealership'}. You can scan the QR code below to open the survey or you can copy the url and share it manually. ${kiosk ? 'This survey will automaticaly reset after each submission so the next customer can fill it out.' : ''}`,
                content: (
                    <div style={{
                        padding: 12,
                        paddingTop: 0
                    }}>
                        <img
                        src={qr_code}
                        style={{
                            width: 250,
                            height: 250,
                            objectFit: 'contain',
                            borderRadius: 10,
                            overflow: 'hidden'
                        }} />
                    </div>
                ),
                buttons: [{
                    key: 'copy',
                    title: 'Copy URL',
                    style: 'default'
                },{
                    key: 'download',
                    title: 'Download QR Code',
                    style: 'default'
                },{
                    key: 'cancel',
                    title: 'Cancel',
                    style: 'cancel'
                }],
                onClick: key => {
                    if(key === 'copy') {
                        onCopyProgramURL(url);
                        return;
                    }
                    if(key === 'download') {
                        onDownloadProgramQRCode(qr_code);
                        return;
                    }
                }
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue creating your link for ${user ? user.full_name : 'this Program'}. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onLeadScriptClick = () => {
        utils.layer.open({
            Component: LeadScriptEditor.bind(this, {
                utils: utils,
                value: program.lead_script.text
            }),
            id: 'lead_script_editor',
            permissions: ['leads.scripts.actions.edit']
        });
    }

    const onOptionsClick = evt => {

        let { dealership, level } = utils.user.get();
        let canEdit = level === User.levels.get().admin || program.dealership_id === dealership.id ? true : false;
        
        utils.sheet.show({
            items: [{
                key: 'customize_agreement',
                permissions: ['programs.actions.edit_agreement'],
                title: 'Customize Agreement',
                style: 'default',
                visible: program.id === 1
            },{
                key: 'enroll_user',
                permissions: ['programs.actions.enroll'],
                title: 'Enroll Safety Associate',
                style: 'default',
                visible: program.allow_sharing === false
            },{
                key: 'generate_url',
                permissions: ['programs.surveys.actions.url.new'],
                title: 'Generate a Public URL',
                style: 'default',
                visible: program.allow_sharing === true
            },{
                key: 'active',
                permissions: program.allow_sharing ? ['programs.surveys.actions.status'] : ['programs.actions.status'],
                title: `${abstract.object.active ? 'Deactivate' : 'Activate'} ${Utils.ucFirst(getLabel())}`,
                style: abstract.object.active ? 'destructive' : 'default',
                visible: canEdit
            }],
            target: evt.target
        }, key => {
            if(key === 'customize_agreement') {
                onCustomizeCareAndShareAgreement();
                return;
            }
            if(key === 'generate_url') {
                setTimeout(onGenerateURL, 500);
                return;
            }
            if(key === 'enroll_lead') {
                utils.layer.open({
                    id: `enroll_program_lead_${abstract.getID()}`,
                    abstract: abstract,
                    Component: EnrollProgramLead
                })
                return;
            }
            if(key === 'enroll_user') {
                utils.layer.open({
                    id: `enroll_program_user_${abstract.getID()}`,
                    abstract: abstract,
                    Component: EnrollProgramUser
                })
                return;
            }
            if(key === 'active') {
                onChangeActiveStatus();
                return;
            }
        });
    }

    const onPreviewQRCode = ({ qr_code, url }) => {
        utils.alert.show({
            title: 'QR Code Preview',
            message: `You can scan the QR Code below to open the Survey or you can copy the url and share it manually`,
            content: (
                <div style={{
                    padding: 12,
                    paddingTop: 0
                }}>
                    <img
                    src={qr_code}
                    style={{
                        width: 250,
                        height: 250,
                        objectFit: 'contain',
                        borderRadius: 10,
                        overflow: 'hidden'
                    }} />
                </div>
            ),
            buttons: [{
                key: 'copy',
                title: 'Copy URL',
                style: 'default'
            },{
                key: 'download',
                title: 'Download QR Code',
                style: 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: 'cancel'
            }],
            onClick: key => {
                if(key === 'copy') {
                    onCopyProgramURL(url);
                    return;
                }
                if(key === 'download') {
                    onDownloadProgramQRCode(qr_code);
                    return;
                }
            }
        });
    }

    const onPrivacyClick = () => {
        utils.layer.open({
            id: `program_privacy_editor_${abstract.getID()}`,
            abstract: abstract,
            Component: ProgramPrivacyEditor.bind(this, {
                preview: true,
                content: abstract.object.privacy
            })
        });
    }

    const onTemplateClick = () => {
        utils.layer.open({
            abstract: Abstract.create({
                object: program.template,
                type: 'template'
            }),
            Component: TemplateDetails,
            id: `template_details_${program.template.id}`,
            permissions: ['programs.templates.details']
        });
    }

    const onUrlClick = url => {
        utils.sheet.show({
            items: [{
                key: 'set_status',
                permissions: ['programs.surveys.actions.url.status'],
                title: url.active ? 'Deactivate' : 'Activate',
                style: url.active ? 'destructive' : 'default'
            },{
                key: 'copy',
                title: 'Copy URL',
                style: 'default'
            },{
                key: 'qr_code',
                title: 'View QR Code',
                style: 'default',
                visible: url.qr_code ? true : false
            }]
        }, key => {
            if(key === 'set_status') {
                onChangeUrlActiveStatus(url);
                return;
            }
            if(key === 'copy') {
                onCopyProgramURL(url.short_url);
                return;
            }
            if(key === 'qr_code') {
                onPreviewQRCode(url);
                return;
            }
        })
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            permissions: program.allow_sharing ? ['programs.surveys.actions.edit'] : ['programs.actions.edit'],
            text: 'Edit'
        }];
    }

    const getDomainNameItems = () => {

        // prepare default list of items
        let items = [{
            id: 'gdl-link.com',
            title: 'gdl-link.com'
        },{
            id: 'survey-box.com',
            title: 'survey-box.com'
        },{
            id: 'surveydropbox.com',
            title: 'surveydropbox.com'
        }];

        // add replicated website domain name if applicable
        let { replicated_website } = utils.dealership.get().preferences || {};
        if(replicated_website && replicated_website.url) {
            items.push({
                id: 'replicated_website_url',
                title: replicated_website.url.replace('https://', '')
            });
        }

        // return list of alphabetically sorted domain name items
        return items.sort((a,b) => {
            return a.title.localeCompare(b.title);
        });
    }

    const getFields = () => {

        if(!program) {
            return null;
        }

        // prepare field mapper items
        let items = [{
            key: 'about',
            permissions: program.allow_sharing ? ['programs.surveys.details.general'] : ['programs.details.general'],
            title: `About this ${program.allow_sharing ? 'Survey' : 'Program'}`,
            items: [{
                key: 'category',
                title: 'Category',
                value: program.category === 'recruiting' ? 'Recruiting' : 'Lead Generation'
            },{
                key: 'description',
                title: 'Description',
                value: program.description || 'No description provided'
            },{
                key: 'id',
                title: 'ID',
                value: program.id
            },{
                key: 'name',
                title: 'Name',
                value: program.name
            },{
                key: 'privacy',
                title: 'Terms and Conditions',
                value: program.privacy ? 'Added' : 'Not Added',
                ...program.privacy ? onPrivacyClick : null
            }]
        },{
            key: 'details',
            permissions: program.allow_sharing ? ['programs.surveys.details.ext']: ['programs.details.ext'],
            title: 'Details',
            items: [{
                key: 'allow_sharing',
                title: 'Allow Sharing',
                value: program.allow_sharing ? 'Yes' : 'No'
            },{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                value: program.dealership ? program.dealership.name : null
            },{
                key: 'demo_goals',
                title: 'Demos Goal',
                visible: program.props && program.props.goals && program.props.goals.demos ? true : false,
                value: program.props && program.props.goals && program.props.goals.demos ? `${program.props.goals.demos} ${program.props.goals.demos === 1 ? 'Demo':'Demos'}` : null
            },{
                key: 'end_date',
                title: 'End Date',
                visible: program.end_date ? true : false,
                value: program.end_date ? moment(program.end_date).format('MMMM Do, YYYY [at] h:mma') : null
            },{
                key: 'end_days',
                title: 'End Date',
                visible: program.end_days ? true : false,
                value: program.end_days ? `${program.end_days} ${program.end_days === 1 ? 'day':'days'} from signup` : null
            },{
                key: 'interactions',
                visible: program.allow_sharing,
                title: 'Interactions',
                value: interactionsCount === null ? 'Loading...' : interactionsCount
            },{
                key: 'lead_script',
                visible: program.allow_sharing ? true : false,
                title: 'Lead Script',
                value: program.lead_script ? program.lead_script.title : null,
                onClick: program.lead_script ? onLeadScriptClick : null
            },{
                key: 'lead_type',
                visible: program.allow_sharing ? true : false,
                title: 'Lead Type',
                value: program.lead_type ? program.lead_type.text : null
            },{
                key: 'template',
                visible: program.allow_sharing,
                title: 'Template',
                value: program.template ? program.template.title : null
            },{
                key: 'url',
                onClick: onGenerateURL,
                value: 'Click to Create',
                visible: program.allow_sharing,
                title: 'URL'
            }]
        }];

        // prevent moving forward if no valid items were provided
        let count = formatFields(utils, items).length;
        if(count === 0) {
            return null;
        }

        return (
            <FieldMapper 
            fields={items}  
            utils={utils} />
        )
    }

    const getLabel = () => {
        return program && program.allow_sharing === true ? 'survey' : 'program';
    }

    const getURL = () => {
        return `${API.server}/leads/index.php?id=${abstract.getID()}&preview=1&dealership_id=${utils.dealership.get().id}&enrollment_user_id=${utils.user.get().user_id}&theme=${window.theme}`;
    }

    const getUrls = () => {

        // determine if user has permission to view this content
        if(utils.user.permissions.get('programs.surveys.details.urls') === false) {
            return null;
        }
        
        return urls && urls.length > 0 && (
            <LayerItem title={'Public URLs'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {urls.map((url, index) => {
                        return (
                            Views.entry({
                                badge: {
                                    text: url.active ? null : 'Deactivated',
                                    color: Appearance.colors.grey()
                                },
                                bottomBorder: true,
                                icon: {
                                    path: 'images/short_url_icon.png'
                                },
                                key: index,
                                onClick: onUrlClick.bind(this, url),
                                subTitle: `Created ${Utils.formatDate(url.date)}`,
                                title: url.token
                            })
                        )
                    })}
                    {urlPaging && (
                        <PageControl
                        data={urlPaging}
                        limit={urlLimit.current}
                        offset={urlOffset}
                        onClick={setUrlOffset}/>
                    )}
                </div>
            </LayerItem>
        )
    }

    const getTemplate = () => {

        // determine if user has permission to view this content
        if(utils.user.permissions.get('programs.surveys.details.template') === false) {
            return null;
        }
        
        // prevent moving forward if a program or template are not available
        if(!program || !program.template || program.allow_sharing === false) {
            return null;
        }

        return (
            <LayerItem title={'Template'}>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 8
                }}>
                    {Views.entry({
                        bottomBorder: false,
                        icon: {
                            path: 'images/template-icon-blue.png'
                        },
                        onClick: onTemplateClick,
                        subTitle: program.template.description,
                        title: program.template.title
                    })}
                </div>
                <div style={{
                    ...Appearance.styles.unstyledPanel(),
                    marginBottom: 20
                }}>
                    <div style={{
                        borderBottom: `1px solid ${Appearance.colors.divider()}`,
                        height: 350
                    }}>
                        <iframe
                        className={'template-preview'}
                        src={getURL()}
                        style={{
                            border: 'none',
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            height: 350,
                            width: '100%'
                        }} />
                    </div>
                    <div style={{
                        padding: 12,
                        textAlign: 'center'
                    }}>
                        <span
                        className={'text-button'}
                        onClick={() => window.open(getURL())}
                        style={{
                            ...Appearance.textStyles.title(),
                            color: Appearance.colors.primary()
                        }}>{'Open Preview in New Window'}</span>
                    </div>
                </div>
            </LayerItem>
        )
    }

    const fetchDetails = async () => {
        try {
            let { interactions_count } = await Request.get(utils, '/programs/', {
                id: abstract.getID(),
                type: 'ext_details'
            });

            setLoading(false);
            setInteractionsCount(interactions_count);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the interactions count for this survey. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const fetchURLs = async () => {
        try {
            let { paging, urls } = await Request.get(utils, '/programs/', {
                limit: urlLimit.current,
                offset: urlOffset,
                program_id: abstract.getID(),
                type: 'public_urls'
            });
            setLoading(false);
            setUrls(urls || []);
            setUrlPaging(paging);

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue retrieving the public urls for this program. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    useEffect(() => {
        fetchURLs();
    }, [urlOffset]);

    useEffect(() => {
        fetchDetails();
        utils.content.subscribe(layerID, ['program'], {
            onUpdate: _abstract => {
                setProgram(program => {
                    return program.id === _abstract.getID() ? _abstract.object : program;
                });
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={abstract.getTitle()}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            {getTemplate()}
            {getFields()}
            {getUrls()}
        </Layer>
    )
}

export const TemplateDetails = ({ abstract, index, options, utils }) => {

    const layerID = `template_details_${abstract.getID()}`;
    const [loading, setLoading] = useState(false);
    const [url, setURL] = useState(null);

    const onChangeActiveStatus = () => {
        utils.alert.show({
            title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
            message: `Are you sure that you want to set "${abstract.object.title}" as ${abstract.object.active ? 'inactive' : 'active'}?`,
            buttons: [{
                key: 'confirm',
                title: `Set as ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            },{
                key: 'cancel',
                title: 'Cancel',
                style: abstract.object.active ? 'default' : 'destructive'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onChangeActiveStatusConfirm();
                }
            }
        });
    }

    const onChangeActiveStatusConfirm = async () => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);

            let next_status = !abstract.object.active;
            await Request.post(utils, '/programs/', {
                type: 'set_template_active_status',
                active: next_status,
                id: abstract.getID()
            });

            abstract.object.active = next_status;
            utils.content.update(abstract);

            setLoading(false);
            utils.alert.show({
                title: 'All Done!',
                message: `This template has been set as ${abstract.object.active ? 'active' : 'inactive'}`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue updating the active status for this template. ${e.message || 'An unknown error occurred'}`
            });
        }
    }

    const onEditClick = () => {

        // prevent moving forward if program is global and user is not an administrator
        if(!abstract.object.dealership_id && utils.user.get().level > User.levels.get().admin) {
            utils.alert.show({
                title: 'Just a Second',
                message: 'This template is available to everyone in the Global Data network and can only be modified by the Home Office. Please create a new template if you need to change the information in this template.'
            });
            return;
        }

        // open template editing layer
        utils.layer.open({
            abstract: abstract,
            Component: AddEditTemplate.bind(this, {
                isNewTarget: false
            }),
            id: `edit_template_${abstract.getID()}`,
            permissions: ['programs.templates.actions.edit']
        });
    }

    const onOptionsClick = evt => {
        utils.sheet.show({
            items: [{
                key: 'active',
                permissions: ['programs.templates.actions.status'],
                title: `Change to ${abstract.object.active ? 'Inactive' : 'Active'}`,
                style: abstract.object.active ? 'destructive' : 'default'
            }],
            target: evt.target
        }, key => {
            if(key === 'active') {
                onChangeActiveStatus();
            }
        });
    }

    const getButtons = () => {
        return [{
            color: 'secondary',
            key: 'options',
            onClick: onOptionsClick,
            text: 'Options'
        },{
            color: 'primary',
            key: 'edit',
            onClick: onEditClick,
            permissions: ['programs.templates.actions.edit'],
            text: 'Edit'
        }];
    }

    const getFields = () => {

        let template = abstract.object;
        let items = [{
            key: 'details',
            title: 'Details',
            items: [{
                key: 'category',
                title: 'Category',
                value: template.category === 'recruiting' ? 'Recruiting' : 'Lead Generation'
            },,{
                key: 'dealership',
                title: 'Dealership',
                visible: utils.user.get().level <= User.levels.get().admin,
                value: template.dealership ? template.dealership.name : null
            },{
                key: 'description',
                title: 'Description',
                value: template.description
            },{
                key: 'id',
                title: 'ID',
                value: template.id
            },{
                key: 'used_by',
                title: 'In-Use By',
                value: `${template.used_by} ${template.used_by === 1 ? 'program' : 'programs'}`
            },{
                key: 'request_demo',
                title: 'Request a Demo',
                value: template.request_demo ? 'Yes' : 'No'
            },{
                color: template.active ? Appearance.colors.green : Appearance.colors.red,
                key: 'active',
                title: 'Status',
                value: template.active ? 'Active' : 'Inactive'
            },{
                key: 'title',
                title: 'Title',
                value: template.title
            }]
        }]

        return items;
    }

    const getURL = () => {
        return `${API.server}/leads/index.php?template_id=${abstract.getID()}&dealership_id=${utils.dealership.get().id}&preview=1&v=${moment().unix()}`;
    }

    useEffect(() => {
        setURL(getURL());
        utils.content.subscribe(layerID, ['template'], {
            onFetch: () => {
                setURL(getURL());
            },
            onUpdate: () => {
                setURL(getURL());
            }
        });
        return () => {
            utils.content.unsubscribe(layerID);
        }
    }, []);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={`Details for ${abstract.getTitle()}`}
        utils={utils}
        options={{
            ...options,
            loading: loading,
            sizing: 'medium'
        }}>
            <div style={{
                ...Appearance.styles.unstyledPanel(),
                marginBottom: 20
            }}>
                <div style={{
                    borderBottom: `1px solid ${Appearance.colors.divider()}`,
                    height: 350
                }}>
                    <iframe
                    className={'template-preview custom-scrollbars'}
                    src={url}
                    style={{
                        border: 'none',
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        height: 350,
                        width: '100%'
                    }} />
                </div>
                <div style={{
                    padding: 12,
                    textAlign: 'center'
                }}>
                    <span
                    className={'text-button'}
                    onClick={() => window.open(`${API.server}/leads/index.php?template_id=${abstract.getID()}&preview=1`)}
                    style={{
                        ...Appearance.textStyles.title(),
                        color: Appearance.colors.primary()
                    }}>{'Open Preview in New Window'}</span>
                </div>
            </div>
            <FieldMapper fields={getFields()} />
        </Layer>
    )
}

export const TemplateQuestionLibrary = ({ onChooseQuestion, onRemoveQuestion }, { abstract, index, options, utils }) => {

    const layerID = `template_question_library_${abstract.getID()}`;
    const limit = 5;

    const [layerState, setLayerState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [paging, setPaging] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [selected, setSelected] = useState(null);
    const [questions, setQuestions] = useState([]);

    const onDeleteQuestion = (question, evt) => {
        evt.stopPropagation();
        utils.alert.show({
            title: 'Delete Question',
            message: 'Are you sure that you want to delete this question? This can not be undone.',
            buttons: [{
                key: 'confirm',
                title: 'Yes',
                style: 'destructive'
            },{
                key: 'cancel',
                title: 'Do Not Delete',
                style: 'default'
            }],
            onClick: key => {
                if(key === 'confirm') {
                    onDeleteQuestionConfirm(question);
                    return;
                }
            }
        })
    }

    const onDeleteQuestionConfirm = async question => {
        try {
            setLoading(true);
            await Utils.sleep(0.25);
            await Request.post(utils, '/dealerships/', {
                type: 'delete_template_question',
                id: question.id
            });

            setLoading(false);
            if(typeof(onRemoveQuestion) === 'function') {
                onRemoveQuestion(question);
            }

            fetchQuestions();
            utils.alert.show({
                title: 'All Done!',
                message: `The "${question.title}" question has been deleted.`
            });

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue deleting this question. ${e.message || 'An unknown error occurred'}`
            })
        }
    }

    const onDoneClick = () => {
        setLayerState('close');
        if(typeof(onChooseQuestion) === 'function') {
            onChooseQuestion(selected);
        }
    }

    const onQuestionClick = question => {
        setSelected(question);
    }

    const getBadges = question => {
        let badges = [{
            text: Program.Template.Question.input.getLabel(question),
            color: Appearance.colors.grey()
        }];
        if(selected && selected.id === question.id) {
            badges.push({
                text: 'Selected',
                color: Appearance.colors.primary()
            })
        }
        return badges;
    }

    const getButtons = () => {
        if(!selected) {
            return null;
        }
        return [{
            key: 'done',
            text: 'Done',
            color: 'primary',
            onClick: onDoneClick
        }]
    }

    const fetchQuestions = async () => {
        try {
            let { paging, questions } = await Request.get(utils, '/dealerships/', {
                type: 'template_questions',
                dealership_id: utils.dealership.get().id,
                offset: offset,
                limit: limit,
                search_text: searchText
            });

            setLoading(false);
            if(!searchText && questions.length === 0) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'No questions have been added for your Dealership. Questions will be available here after at least one Program has been created.',
                    onClick: () => setLayerState('close')
                });
                return;
            }
            setPaging(paging);
            setQuestions(questions.map(question => Program.Template.Question.create(question)));

        } catch(e) {
            setLoading(false);
            utils.alert.show({
                title: 'Oops!',
                message: `There was an issue loading your library of questions. ${e.message || 'An unknown error occurred'}`,
                onClick: () => setLayerState('close')
            })
        }
    }

    useEffect(() => {
        fetchQuestions();
    }, [offset, searchText]);

    return (
        <Layer
        buttons={getButtons()}
        id={layerID}
        index={index}
        title={'Template Questions Library'}
        utils={utils}
        options={{
            ...options,
            layerState: layerState,
            loading: loading == true,
            removePadding: true,
            sizing: 'medium'
        }}>
            <div style={{
                borderBottom: `1px solid ${Appearance.colors.divider()}`,
                padding: 15
            }}>
                <TextField
                icon={'search'}
                onChange={setSearchText} 
                placeholder={'Search by question title...'}/>
            </div>
            <div style={{
                padding: 15
            }}>
                <div style={{
                    ...Appearance.styles.unstyledPanel()
                }}>
                    {questions.map((question, index) => {
                        return (
                            Views.entry({
                                key: index,
                                title: question.title,
                                subTitle: Program.Template.Question.input.getDescription(question),
                                badge: getBadges(question),
                                hideIcon: true,
                                firstItem: index === 0,
                                singleItem: questions.length === 0,
                                lastItem: index === questions.length - 1,
                                bottomBorder: index !== questions.length - 1,
                                onClick: onQuestionClick.bind(this, question),
                                rightContent: (
                                    <img
                                    className={'text-button'}
                                    src={'images/red-x-icon.png'}
                                    onClick={onDeleteQuestion.bind(this, question)}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        objectFit: 'contain',
                                        marginLeft: 8
                                    }} />
                                )
                            })
                        )
                    })}
                </div>
                <PageControl
                data={paging}
                limit={limit}
                offset={offset}
                onClick={next => {
                    setLoading(true);
                    setOffset(next);
                }} />
            </div>
        </Layer>
    )
}
